import React from "react";
import TeamScreen from "./view/TeamScreen";
import OrgIcon from "./OrgIcon";

const config = {
  sidebar: {
    title: "Organization",
    icon: OrgIcon,
    items: [
      {
        title: "Team",
        path: "/modules/organization/team",
      },
    ],
  },
  routes: [
    {
      path: "/modules/organization/team",
      main: TeamScreen,
    },
  ],
};

export default config;
