import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import LoggedInSideBarView from "../components/LoggedInSideBarView";
import { ModuleMap } from "../modules/config";
import Welcome from "../view/Welcome";
import { ModuleMapUserInfo } from "../screens/user/config";
import LoggedInUserInfoSideBarView from "../components/LoggedInUserInfoSideBarView";
import Login from "../screens/Login";
import NotFoundScreen from "../screens/NotFoundScreen";
import { BrowserView, MobileView } from "react-device-detect";
import ProfileBox from "../components/ProfileBox";

const LoggedOutRoutes = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="MainContent" style={{ marginLeft: 0, padding: 0 }}>
        <div style={{ width: "95vw", maxWidth: 580 }}>
          <Switch>
            <Route key="login" path="/login" exact={false} component={() => <Login />} />
            <Route
              render={(props) => (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: { pathname: "/welcome" } },
                  }}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const LoggedInWithAccessRoutes = ({ routes, userRoutes }) => {
  return (
    <div>
      {/* Redirect root and login to welcome screen when logged in */}
      <Route key="root" path="/" exact={true} component={() => <Redirect from="/" to="/welcome" />} />
      <Route key="login" path="/login" exact={true} component={() => <Redirect from="/" to="/welcome" />} />
      <Route
        path="/"
        component={({ location }) => {
          // determine base path in case this has different look and feel
          const pathBase = location.pathname.split("/")[1];
          if (pathBase === "user") {
            return (
              <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                <div>
                  <BrowserView>
                    <LoggedInUserInfoSideBarView />
                  </BrowserView>
                </div>

                <div className="MainContent">
                  <Switch>
                    {/* Redirect logged in users to user profile for user root for simplicity */}
                    <Route
                      key="profile"
                      path="/user"
                      exact={true}
                      component={() => <Redirect from="/user" to="/user/profile" />}
                    />

                    {
                      // determine routes based on module map and permissions
                      userRoutes.map((item, index) => {
                        return <PrivateRoute key={index} path={item.path} component={item.main} />;
                      })
                    }
                  </Switch>
                </div>
                <MobileView>
                  <div
                    style={{
                      position: "absolute",
                      top: 75,
                      left: 0,
                      zIndex: 2,
                    }}
                  >
                    <LoggedInUserInfoSideBarView />
                  </div>
                </MobileView>
                <ProfileBox />
              </div>
            );
          }
          return (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <BrowserView>
                  <LoggedInSideBarView />
                </BrowserView>
              </div>
              <div className="MainContent">
                <Switch>
                  {
                    // determine routes based on module map and permissions
                    routes.map((item, index) => {
                      return <PrivateRoute key={index} path={item.path} component={item.main} />;
                    })
                  }
                  {/* Fall through screen is 404 */}
                  <Route component={() => <NotFoundScreen />} />
                </Switch>
              </div>
              <MobileView>
                <div
                  style={{
                    position: "absolute",
                    top: 75,
                    left: 0,
                    zIndex: 2,
                  }}
                >
                  <LoggedInSideBarView />
                </div>
              </MobileView>
              <ProfileBox />
            </div>
          );
        }}
      />
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const ActionRoutes = ({ selectedSite }) => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);

  const [routes, setRoutes] = useState([]);
  const [ready, setReady] = useState(false);
  const [userRoutes, setUserRoutes] = useState([]);

  useEffect(() => {
    const bConfigDataReady = configuration.currentState == "SUCCESS";
    const bUserDataReady = !authentication.initializing;

    // if user is not logged in, we can proceed immediately
    if (bUserDataReady && !authentication.loggedIn) {
      setReady(true);
    }

    // grab the user routes for settings (not site specific)
    setUserRoutes(getUserRoutes());

    if (bConfigDataReady && bUserDataReady) {
      if (authentication.loggedIn) {
        // determine routes for logged in user
        const role = authentication.user.access[selectedSite.domain][selectedSite.site].role ?? "resident";
        const permissions = authentication.user.access[selectedSite.domain][selectedSite.site].permissions ?? [];
        const supportedModules = configuration.data.modules;
        setRoutes(getPrivateRoutes(role, permissions, supportedModules));
      }
      console.log("Config data is ready");
      setReady(true);
    } else {
      // if there is no selected site or configuration coming
      // assume the user has no access to anything apart from welcome screen
      if (configuration.currentState === "READY") {
        setRoutes([
          {
            path: "/welcome",
            main: () => <Redirect from="/welcome" to="/user/invitations" />,
          },
        ]);
        setReady(true);
      }
    }
  }, [configuration, selectedSite, authentication]);

  const getUserRoutes = () => {
    let uRoutes = [];
    for (const module in ModuleMapUserInfo) {
      uRoutes.push(...ModuleMapUserInfo[module].routes);
    }
    return uRoutes;
  };

  const getPrivateRoutes = (role, permissions, supportedModules) => {
    let routes = [];

    // welcome screen route
    routes.push({
      path: "/welcome",
      main: () => <Welcome />,
    });

    const bIsAdmin = role?.toLowerCase() === "admin";
    for (var module in ModuleMap) {
      const bHasModule = supportedModules[module] != null;
      const bEnabled = bHasModule && supportedModules[module].enabled;
      const bHasPermission = permissions.includes(module);
      if (bEnabled && (bHasPermission || bIsAdmin)) {
        routes.push(...ModuleMap[module].routes);
      }
    }
    return routes;
  };

  if (!ready) {
    return null;
  }

  // if you are not logged in, you only have access to the login screen
  if (!authentication.loggedIn) {
    return <LoggedOutRoutes />;
  }

  // if you are logged in and have access to domains, you can see all the routes made available to you
  return <LoggedInWithAccessRoutes routes={routes} userRoutes={userRoutes} />;
};
