import React, { Fragment, useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { authStateMonitor } from "./actions/authentication";
import { fetchConfiguration } from "./actions/config";

import { BrowserRouter } from "react-router-dom";
import { ActionRoutes } from "./navigation/ActionRoutes";
import LoadingScreen from "./screens/LoadingScreen";
import NavHeader from "./components/NavHeader";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReactGA from "react-ga";
import { useRecoilState } from "recoil";
import { SelectedSiteState } from "./SharedState";
import axios from "axios";

const Config = require("./FirebaseConstants.json");

// initialize global firebase configuration
firebase.initializeApp(Config.firebase);
new firebaseui.auth.AuthUI(firebase.auth());

// initialize google analytics
ReactGA.initialize(Config.ga);

const NewApp = () => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);

  const [selectedSite, setSelectedSite] = useRecoilState(SelectedSiteState);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    // track auth state
    authStateMonitor();
  }, []);

  // when user logs in, fetch site configuration
  useEffect(() => {
    // not ready to proceed
    if (authentication.initializing) {
      return;
    }

    if (authentication.loggedIn) {
      // our initial domain and building will initially be set to the first item
      // perhaps we can switch this to most recently accessed

      const domains = Object.keys(authentication.user?.access);
      const initialDomain = domains.length > 0 ? domains[0] : null;

      if (initialDomain) {
        const domainSites = Object.keys(authentication.user.access[initialDomain]);
        const initialSite = domainSites.length > 0 ? domainSites[0] : null;
        setSelectedSite({ domain: initialDomain, site: initialSite });
      } else {
        console.log("This user has no access, nothing else to process");
        setReady(true);
      }
    } else {
      setReady(true);
    }
  }, [authentication]);

  const onRouterRef = useCallback((ref) => {
    if (ref) {
      ref.history.listen(() => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  useEffect(() => {
    if (selectedSite) {

      // set axios common defaults for domain and site
      // for use in all requests
      axios.defaults.params = {
        building: selectedSite.site,
        domain: selectedSite.domain
      };

      fetchConfiguration(selectedSite.domain, selectedSite.site);
    } else {
      // clear axios default headers
      axios.defaults.params = {};
    }
  }, [selectedSite]);

  useEffect(() => {
    if (configuration.currentState === "SUCCESS") {
      setReady(true);
    }
  }, [configuration.currentState]);

  const bShowSpinner = !ready;
  return (
    <Fragment>
      <NavHeader />
      <BrowserRouter ref={onRouterRef}>
        <div>
          <NotificationContainer />
          {bShowSpinner ? (
            <div style={{ paddingTop: 100 }}>
              <LoadingScreen />
            </div>
          ) : (
            <Fragment>
              <ActionRoutes selectedSite={selectedSite} />
            </Fragment>
          )}
        </div>
      </BrowserRouter>
    </Fragment>
  );
};

export default NewApp;
