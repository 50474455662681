import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { fetchInvitations } from '../../../../actions/authentication';
import LoadingScreen from '../../../LoadingScreen';
import ActionBox from '../../../../components/ActionBox';
import ModuleHeader from '../../../../components/ModuleHeader';

const UserInvitationsScreen = () => {
  const authentication = useSelector(state => state.authentication);
  const configuration = useSelector(state => state.configuration);
  const [invites, setInvites] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    ReactGA.pageview('/invitation');

    fetchInvitations(authentication.user.id)
      .then(invites => {
        setInvites([]);
        setReady(true);
      })
      .catch(() => {
        setInvites([]);
        setReady(true);
      });
  }, []);

  if (!ready) {
    return (
      <div style={{ marginTop: 30 }}>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div>
      <ModuleHeader title='Account' subTitle='Pending Invitations' />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {invites.length === 0
          ? <h3 style={{fontWeight: 200}}>You have no pending invitations</h3>
          : invites.map(inviteId => {
              const decodedInvite = JSON.parse(Buffer(inviteId, 'base64').toString());
              return (
                <div key={inviteId} style={{ marginBottom: 30 }}>
                  <ActionBox
                    width='50%'
                    heading={`You have been invited to be an ${decodedInvite.type} for`}
                    subHeading={`${configuration.data.sitenames[decodedInvite.site]}`}
                    action='Accept'
                  />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default withRouter(UserInvitationsScreen);
