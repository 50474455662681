import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

import { Provider } from "react-redux";
import store from "./store";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Provider>,
  document.getElementById("root")
);
unregister();
