import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import Gravatar from "react-gravatar";
import { ProfileBoxIsOpen, SelectedSiteState } from "../SharedState";
import { Transition } from "react-transition-group";
import { Button } from "reactstrap";
import SiteIcon from "./icons/SiteIcon";
import { userLogout } from "../actions/authentication";

const ProfileBox = () => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);
  const [sites, setSites] = useState([]);

  const [isProfileOpen, setProfileOpen] = useRecoilState(ProfileBoxIsOpen);
  const [selectedSite, setSelectedSite] = useRecoilState(SelectedSiteState);

  useEffect(() => {
    if (authentication.loggedIn) {
      // transform sites to friendly names using global config data

      // retrieve all available domains and sites (ignore domains for moment)
      let sitesWithDomain = [];
      Object.keys(authentication.user.access).forEach((domainId) => {
        Object.keys(authentication.user.access[domainId]).forEach((siteId) => {
          const siteInfo = authentication.user.access[domainId][siteId];
          sitesWithDomain.push({
            id: siteId,
            domainId: domainId,
            name: siteInfo?.name,
          });
        });
      });
      setSites(sitesWithDomain);
    }
  }, [configuration.data.sitenames]);

  return (
    <Transition in={isProfileOpen} timeout={0}>
      {(state) => (
        <div
          className={`ProfileBox-${state}`}
          id="user_profile_dropdown"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Gravatar
              style={{ borderRadius: 5 }}
              email={authentication.user.email}
              size={72}
              rating="pg"
              default="monsterid"
              className="CustomAvatar-image"
            />
          </div>
          <h2>{authentication.user.name}</h2>
          <h3>{authentication.user.email}</h3>

          <div
            className="ProfileBox-User"
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignSelf: "center",
            }}
            onClick={() => window.open("/user", "_self")}
          >
            <span style={{ marginRight: 10 }}>
              <img alt="back" src={require("../img/account_circle.svg")} />
            </span>
            Account Settings
          </div>
          <div
            style={{
              display: "flex",
              borderTop: "solid 2px #F3F3F3",
              marginTop: 10,
              marginBottom: 10,
              flexDirection: "column",
            }}
          >
            {selectedSite &&
              sites.map((site) => {
                return (
                  <div
                    key={site.id}
                    className="ProfileBox-LineItem"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifySelf: "center",
                    }}
                    onClick={() => {
                      setSelectedSite({ domain: site.domainId, site: site.id });
                      setProfileOpen(false);
                    }}
                  >
                    <SiteIcon
                      color={
                        selectedSite.site === site.id ? "#FF8866" : "#464861"
                      }
                    />
                    <span style={{ marginLeft: 10 }}>{site.name}</span>
                  </div>
                );
              })}
          </div>
          <Button
            style={{ justifySelf: "flex-end" }}
            onClick={() => userLogout()}
          >
            Logout
          </Button>
        </div>
      )}
    </Transition>
  );
};

export default ProfileBox;
