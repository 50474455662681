import React from "react";

const PreferencesIcon = (props) => (
  <svg width={20} height={16} {...props}>
    <path
      d="M13 10c1.272 0 2.407.802 2.83 2.002H19a1 1 0 1 1 0 2h-3.17a3.001 3.001 0 0 1-5.66 0H1a1 1 0 0 1 0-2h9.17A3.001 3.001 0 0 1 13 10zM4.17 2.002a3.001 3.001 0 0 1 5.66 0H19a1 1 0 0 1 0 2H9.83a3.001 3.001 0 0 1-5.66 0H1a1 1 0 1 1 0-2h3.17zm2.83 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export default PreferencesIcon;
