import React, { useEffect } from "react";
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import { signInWithApple } from "../actions/authentication";

var uiConfig = {
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  signInFlow: "popup",
  signInSuccessUrl: "/welcome",
};

export default function LoginProviders() {
  const onSignInWithApple = () => {
    signInWithApple()
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    var ui = firebaseui.auth.AuthUI.getInstance();
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  return (
    <>
      <div id="firebaseui-auth-container" />
      <div className="LoginButtonContainer">
        <div className="apple-sign-in" onClick={onSignInWithApple}>
           Sign in with Apple
        </div>
      </div>
    </>
  );
}
