import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

const fetchAllUsers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/residents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const residents = [];
      data.users.forEach((resident) => {
        residents.push({
          id: resident.uid,
          email: resident.email,
          room: resident.room,
          comments: resident.comments,
          pending: resident.pending,
        });
      });

      resolve(residents);
    } catch {
      reject();
    }
  });
};

const updateUser = async (uid, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/residents/${uid}`,
        {
          ...payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
};

const inviteResident = (building, domain, email) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!email || email.length === 0) {
        throw new Error("Invalid email address");
      }

      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/auth/invite`,
        {
          domain,
          building,
          email,
          role: "resident",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const enableUser = async (uid, enabled) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/residents/${uid}/enable`,
        {
          enabled: enabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
};

const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        reject();
      });
  });
};

export {
  fetchAllUsers,
  enableUser,
  updateUser,
  resetPassword,
  inviteResident,
};
