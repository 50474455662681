import React from "react";

const HamburgerIcon = (props) => (
  <svg width={32} height={21} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M0 0h32v3H0zM0 9h32v3H0zM0 18h32v3H0z" />
    </g>
  </svg>
);

export default HamburgerIcon;
