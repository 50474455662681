import React from "react";
import NewsManagementScreen from "./view/NewsManagementScreen";
import NewsItemScreen from "./view/NewsItemScreen";
import InformationScreen from "./view/InformationModuleSettings";
import BuildingSettings from "./view/BuildingSettings";
import BuildingIcon from "./BuildingIcon";

const config = {
  sidebar: {
    title: "Building",
    icon: BuildingIcon,
    items: [
      {
        title: "News & Events",
        path: "/modules/building/news/",
      },
      {
        title: "Information & Contacts",
        path: "/modules/building/info/",
      },
      {
        title: "Settings",
        path: "/modules/building/settings/",
      },
    ],
  },
  routes: [
    {
      path: "/modules/building/news/create",
      main: () => <NewsItemScreen />,
    },
    {
      path: "/modules/building/news/",
      main: () => <NewsManagementScreen />,
    },
    {
      path: "/modules/building/info/",
      main: () => <InformationScreen />,
    },
    {
      path: "/modules/building/settings/",
      main: () => <BuildingSettings />,
    },
  ],
};

export default config;
