import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Badge,
  ListGroup,
  ListGroupItem,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import ReactGA from "react-ga";

import ModuleHeader from "../../../components/ModuleHeader";
import LoadingScreen from "../../../screens/LoadingScreen";
import { fetchAllAgents, inviteAgent, modifyAgent } from "../action";
import { isMobile } from "react-device-detect";
import NotificationManager from "react-notifications/lib/NotificationManager";

const EditAgentModal = ({ isOpen, onToggle, agentId, email, permissions, onUpdate, onInvite, create }) => {
  const configuration = useSelector((state) => state.configuration);

  const [perms, setPerms] = useState([]);
  const [createAgentEmail, setCreateAgentEmail] = useState();

  useEffect(() => {
    setPerms(permissions);
  }, [permissions]);

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <Form
        onSubmit={(event) => {
          // update user permissions
          if (create) {
            onInvite(createAgentEmail, perms);
          } else {
            onUpdate(agentId, perms);
          }

          event.preventDefault();
        }}
      >
        <ModalHeader>{create ? "Invite Agent" : email}</ModalHeader>
        <ModalBody>
          <div>
            {create ? (
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={createAgentEmail}
                  onChange={(event) => setCreateAgentEmail(event.target.value)}
                />
              </FormGroup>
            ) : null}
            <FormGroup>
              <Label for="userpermissions">Permissions</Label>
              {Object.keys(configuration.data.permissions).map((key) => {
                const permission = configuration.data.permissions[key];
                const id = key;
                const title = permission.title;
                const description = permission.description;
                return (
                  <FormGroup check key={id} style={{ marginLeft: 20 }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        disabled={false}
                        checked={perms.includes(key) ?? false}
                        style={{ cursor: "pointer" }}
                        onChange={(event) => {
                          if (event.target.checked) {
                            // add to permissions
                            setPerms((state) => state.concat(key));
                          } else {
                            // remove from permissions
                            setPerms((state) => state.filter((permissions) => permissions !== key));
                          }
                        }}
                      />
                      {title}
                    </Label>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label style={{ fontSize: "0.75em" }}>{description}</Label>
                    </div>
                  </FormGroup>
                );
              })}
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Button color="secondary" onClick={onToggle} style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <div style={{ flex: 1 }} />
            <Button color="success" type="submit">
              {create ? "Invite" : "Save"}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

class TeamManagementScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      agents: [],
      agentSearchFilter: null,
      modalOpen: false,
      currentAgent: null,
    };
  }
  componentDidMount() {
    ReactGA.pageview("/modules/organization/team/");
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configuration.data.id !== this.props.configuration.data.id) {
      this.refresh();
    }
  }

  refresh() {
    this.setState({ loading: true });

    fetchAllAgents()
      .then((agents) => {
        this.setState({ agents: agents, loading: false });
      })
      .catch(() => {
        this.setState({ agents: [], loading: false });
      });
  }

  render() {
    return (
      <Fragment>
        <ModuleHeader
          title="Organization"
          subTitle="Team"
          action
          actionTitle="Invite Agent"
          onAction={this.onInviteAgent}
          search
          onSearchChange={this.onSearchAgents}
        />
        {this.renderAgents()}
        <EditAgentModal
          create={!this.state.agent}
          isOpen={this.state.modalOpen}
          onToggle={() => this.setState({ modalOpen: !this.state.modalOpen, agent: null })}
          agentId={this.state.agent?.id}
          email={this.state.agent?.email}
          permissions={this.state.agent?.permissions ?? []}
          onInvite={(email, permissions) => {
            inviteAgent(this.props.configuration.data.id, email, permissions)
              .then(() => {
                NotificationManager.success("Agent has been invited");
                this.refresh();
                this.setState({
                  modalOpen: !this.state.modalOpen,
                  agent: null,
                });
              })
              .catch(() => {
                NotificationManager.error("Unable to invite agent");
                this.setState({
                  modalOpen: !this.state.modalOpen,
                  agent: null,
                });
              });
          }}
          onUpdate={(agentId, newPermissions) => {
            modifyAgent(
              agentId,
              newPermissions.reduce((acc, permission) => {
                return { ...acc, [permission]: true };
              }, {})
            )
              .then(() => {
                NotificationManager.success("Agent has been modified");
                this.refresh();
                this.setState({
                  modalOpen: !this.state.modalOpen,
                  agent: null,
                });
              })
              .catch(() => {
                NotificationManager.error("Unable to modify agent");
                this.setState({
                  modalOpen: !this.state.modalOpen,
                  agent: null,
                });
              });
          }}
        />
      </Fragment>
    );
  }

  onSearchAgents = (text) => {
    this.setState({ agentSearchFilter: text });
  };

  renderAgents = () => {
    if (this.state.loading) {
      return <LoadingScreen />;
    } else {
      return (
        <Fragment>
          <ListGroup>
            <ListGroupItem
              disabled
              style={{
                backgroundColor: "#e8ecef",
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <div className="ListHeader" style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>agents</div>
              </div>
            </ListGroupItem>
            {this.state.agents.length > 0 ? (
              this.state.agents.map((agent) => {
                // filter out based on search filter (email / firstname / lastname)
                const searchString = agent.email;
                if (
                  this.state.agentSearchFilter &&
                  !searchString.toLowerCase().includes(this.state.agentSearchFilter.toLowerCase())
                ) {
                  return null;
                }
                return (
                  <ListGroupItem key={agent.id} action style={{ cursor: "pointer" }} onClick={this.onModifyUser(agent)}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {agent.email} {agent.pending ? " (pending)" : null}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {agent.permissions.map((permission) => {
                          if (Object.keys(this.props.configuration.data.permissions).includes(permission)) {
                            return (
                              <div
                                key={permission}
                                style={{
                                  marginLeft: isMobile ? 0 : 10,
                                  marginRight: isMobile ? 10 : 0,
                                }}
                              >
                                <Badge>{permission}</Badge>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })
            ) : (
              <div className="EmptyListResponse" style={{ marginTop: 20, textAlign: "center" }}>
                There are no agents
              </div>
            )}
          </ListGroup>
        </Fragment>
      );
    }
  };

  onModifyUser = (user) => () => {
    this.setState({ agent: user, modalOpen: true });
  };

  onInviteAgent = () => {
    this.setState({ agent: null, modalOpen: true });
  };
}

export default withRouter(connect(mapStateToProps)(TeamManagementScreen));
