import React from "react";
import { isMobile } from "react-device-detect";
import { Button, Input } from "reactstrap";

export default class ModuleHeader extends React.PureComponent {
  render() {
    const { title, subTitle, action, actionTitle, search } = this.props;
    return (
      <div
        className="ModuleHeader"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <h2>{`${title} / ${subTitle}`}</h2>
          </div>
          <div
            style={{
              alignContent: "flex-end",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {this.props.children}
            {search ? (
              <div
                style={{
                  paddingRight: isMobile ? 0 : 10,
                  paddingTop: isMobile ? 5 : 0,
                  paddingBottom: isMobile ? 5 : 0,
                }}
              >
                <Input
                  style={{ padding: 10, height: 40 }}
                  type="search"
                  placeholder="Search..."
                  onChange={this.onSearchChange}
                />
              </div>
            ) : null}
            {action ? (
              <Button onClick={this.onAction} style={{ height: 40 }}>
                {actionTitle}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  onSearchChange = (event) => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(event.target.value);
    }
  };

  onAction = () => {
    if (this.props.onAction) {
      this.props.onAction();
    }
  };
}
