import React from 'react';
import { Button } from 'reactstrap';

const ActionBox = props => {
  return (
    <div className="ActionBox" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <h1>{props.heading}</h1>
      <h2>{props.subHeading}</h2>
      <Button color="success" style={{width: '50%'}}>{props.action}</Button>
    </div>
  );
}

export default ActionBox;