import React from "react";

const WrenchIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M13.698 7.303a1 1 0 0 0 0 1.4l1.6 1.599a1 1 0 0 0 1.4 0l3.768-3.77a5.998 5.998 0 0 1-7.938 7.939L5.62 21.379a2.12 2.12 0 1 1-2.999-3l6.908-6.907a5.998 5.998 0 0 1 7.938-7.938l-3.759 3.759-.01.01z"
        fill="#11342F"
      />
    </g>
  </svg>
);

export default WrenchIcon;
