import firebase from "firebase/app";
import axios from "axios";

function _sortPosition(a, b) {
  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function fetchInformationAndContacts() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/building/info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const memos = [];
      data.memos.forEach((memo) => {
        memos.push({
          id: memo.id,
          title: memo.title,
          content: memo.content,
          position: memo.position,
        });
      });

      const contacts = [];
      data.contacts.forEach((contact) => {
        contacts.push({
          id: contact.id,
          contact: contact.contact,
          name: contact.name,
          phone: contact.phone,
          position: contact.position,
        });
      });

      resolve({
        infoItems: memos.sort(_sortPosition),
        contactItems: contacts.sort(_sortPosition),
      });
    } catch {
      reject();
    }
  });
}

export function addInformationItem(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/a/building/info/memo`,
        {
          title: data?.title,
          content: data?.content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateInformationItem(memoId, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/building/info/memo/${memoId}`,
        {
          title: data?.title,
          content: data?.content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteInformationItem(memoId, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/building/info/memo/${memoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function addContact(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/a/building/info/contact`,
        {
          contact: data?.contact,
          name: data?.name,
          phone: data?.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateContact(id, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/building/info/contact/${id}`,
        {
          contact: data?.contact,
          name: data?.name,
          phone: data?.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteContact(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/building/info/contact/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}
