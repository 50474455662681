import React from "react";
import SettingsScreen from "./view/Settings";
import ConciergeIcon from "./ConciergeIcon";
import ElevatorBookings from "./view/ElevatorBookings";
import GuestRegistration from "./view/GuestRegistration";
import MaintanenceItem from "./view/MaintanenceItem";
import MaintenanceItemsScreen from "./view/Maintenance";
import OtherRequests from "./view/OtherRequests";
import OtherRequestItem from "./view/OtherRequestItem";

const config = {
  sidebar: {
    title: "Concierge Services",
    icon: ConciergeIcon,
    items: [
      {
        title: "Maintenance Requests",
        path: "/modules/concierge/maintenance",
      },
      {
        title: "Other Requests",
        path: "/modules/concierge/other",
      },
      {
        title: "Settings",
        path: "/modules/concierge/settings",
      },
    ],
  },
  routes: [
    {
      path: "/modules/concierge/maintenance/:id",
      main: () => <MaintanenceItem />,
    },
    {
      path: "/modules/concierge/maintenance",
      main: () => <MaintenanceItemsScreen />,
    },
    {
      path: "/modules/concierge/guest",
      main: () => <GuestRegistration />,
    },
    {
      path: "/modules/concierge/elevator",
      main: () => <ElevatorBookings />,
    },
    {
      path: "/modules/concierge/other/:id",
      main: () => <OtherRequestItem />,
    },
    {
      path: "/modules/concierge/other",
      main: () => <OtherRequests />,
    },
    {
      path: "/modules/concierge/settings",
      main: () => <SettingsScreen />,
    },
  ],
};

export default config;
