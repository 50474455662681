import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Input, Label, Form, FormGroup } from "reactstrap";
import ModuleHeader from "../../../components/ModuleHeader";
import BackButton from "../../../components/BackButton";
import ImageUploader from "./ImageUploader";
import ModalDialog from "../../../components/ModalDialog";
import ImageSelectorModal from "./ImageSelectorModal";
import { ClipLoader } from "react-spinners";
import { fetchTags } from "../action/settings";
import { saveNewsItem, updateNewsItem, deleteNewsItem } from "../action/news";
import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";
import { TagItem } from "./component/Tag";
import PreferencesIcon from "../../../components/icons/PreferencesIcon";
import SelectableItems from "./component/SelectableItems";
import { SimpleEditor } from "../../../components/SimpleEditor";

const EventTags = ({ tags, selected, onTagsUpdated }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback((event) => {
    let picker = document.getElementById(`selectable_items`);
    let icon = document.getElementById(`preferences_icon`);
    if (
      picker &&
      !picker.contains(event.target) &&
      icon &&
      !icon.contains(event.target)
    ) {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <div id="preferences_icon" className="Hoverable">
        <PreferencesIcon
          style={{ marginLeft: 10 }}
          onClick={() => {
            if (!visible) {
              setVisible(true);
            }
          }}
        />
      </div>
      <div id="selectable_items" style={{ marginLeft: 10 }}>
        {visible ? (
          <SelectableItems
            items={tags}
            selected={selected}
            onSelectedChanged={onTagsUpdated}
          />
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

class NewsItemScreen extends React.Component {
  constructor(props) {
    super(props);

    // item to edit if required
    const existingItem = this.props.location.state.item || {};
    const bCreateMode = existingItem.id == null;

    this.state = {
      editMode: !bCreateMode,
      editModeKey: existingItem.id,
      fetching: false,
      processing: false,
      confirmDelete: false,
      errors: [],
      published_mobile: bCreateMode ? true : existingItem.published_mobile,
      title: existingItem.title,
      description: existingItem.description,
      imageUrl: existingItem.imageUrl,
      initialWidthOfContent: 0,
      dropdownOpen: false,
      previewModeText: "Digital Sign",
      previewMode: "ds",
      selectImageModal: false,
      ds_image_only: bCreateMode ? false : existingItem.ds_image_only,
      tags: [],
      selectedTags: bCreateMode ? [] : existingItem.tags ?? [],
    };
  }

  componentDidMount() {
    const mode = this.state.editMode ? "edit" : "create";
    ReactGA.pageview(`/modules/building/news/${mode}`);

    if (this.state.initialWidthOfContent === 0) {
      this.setState({
        initialWidthOfContent: this.divElement.clientWidth - 10,
      });
    }

    fetchTags()
      .then((tags) => this.setState({ tags: tags }))
      .catch(() => console.log("Error retrieving tags"));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.editMode &&
      this.props.configuration.data.id !== this.props.location.state.site
    ) {
      this.goBack();
    }
  }

  render() {
    const disableInput = this.state.processing;
    const mode = this.state.editMode ? "Edit" : "Create";
    return (
      <div ref={(divElement) => (this.divElement = divElement)}>
        <ModuleHeader
          title="Building"
          subTitle={`${mode} News & Events Item`}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BackButton title="Back to News & Events" onClick={this.goBack} />
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ borderTop: "1px solid #D3D3D3", paddingBottom: 10 }} />
        <Form onSubmit={this._onSaveItem}>
          <FormGroup>
            <Label for="title" style={{ fontSize: "1.5em" }}>
              Title
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              defaultValue={this.state.title}
              onChange={(event) => this.setState({ title: event.target.value })}
              disabled={disableInput}
            />
          </FormGroup>
          <FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label for="title" style={{ fontSize: "1.5em" }}>
                Tags
              </Label>
              <EventTags
                tags={this.state.tags}
                selected={this.state.selectedTags}
                onTagsUpdated={(tags) => this.setState({ selectedTags: tags })}
              />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                }}
              >
                {this.state.selectedTags.map((tagId) => {
                  const tag = this.state.tags.filter(
                    (value) => value.id === tagId
                  )[0];
                  if (tag) {
                    return (
                      <TagItem
                        key={tag.id}
                        title={tag.title}
                        baseColor={tag.color}
                        id={tag.id}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="description" style={{ fontSize: "1.5em" }}>
              Description
            </Label>
            <SimpleEditor
              value={this.state.description || ""}
              onChange={(value) => this.setState({ description: value })}
              disabled={disableInput}
            />
          </FormGroup>
          <FormGroup>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label for="image" style={{ flex: 1, fontSize: "1.5em" }}>
                Image
              </Label>
            </div>

            <br />
            <ImageUploader
              imageUrl={this.state.imageUrl}
              width={this.state.initialWidthOfContent}
              initialValue={this.state.imageUrl}
              onUploadImage={() => this.setState({ selectImageModal: true })}
            />
          </FormGroup>
          <hr />
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.published_mobile}
                onChange={(event) =>
                  this.setState({ published_mobile: event.target.checked })
                }
                disabled={disableInput}
              />
              Publish to Mobile Client
            </Label>
          </FormGroup>

          {this.state.errors.length > 0 ? (
            <Alert color="danger" style={{ marginTop: 10 }}>
              {this.state.errors.map((error) => {
                return (
                  <h1 className="ErrorItem" key={error}>
                    {error}
                  </h1>
                );
              })}
            </Alert>
          ) : null}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 40,
              }}
            >
              <Button
                color="success"
                type="submit"
                disabled={disableInput}
                style={{ marginRight: 5 }}
              >
                {this.state.editMode ? "Update Item" : "Create Item"}
              </Button>
              <Button
                onClick={() => {
                  this.props.history.push("/information/");
                }}
                color="danger"
                disabled={disableInput}
                style={{ marginRight: 5 }}
              >
                Cancel
              </Button>
              <ClipLoader loading={this.state.processing} />
            </div>
            {this.state.editMode ? (
              <div
                style={{
                  alignItems: "right",
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 40,
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({ confirmDelete: true });
                  }}
                  color="danger"
                  disabled={disableInput}
                >
                  Delete Item
                </Button>
                <ModalDialog
                  active={this.state.confirmDelete}
                  title="Delete Confirmation"
                  body="Are you sure you want to delete this item?"
                  confirmColor="danger"
                  cancelColor="secondary"
                  onConfirm={() => this._deleteInformationItem()}
                  onCancel={() => {
                    this.setState({ confirmDelete: false });
                  }}
                />
              </div>
            ) : null}
          </div>
        </Form>

        <ImageSelectorModal
          title="Select Image"
          building={this.props.configuration.data.id}
          active={this.state.selectImageModal}
          onCancel={() => this.setState({ selectImageModal: false })}
          onSuccess={(imageUrl) => {
            this.setState({
              imageUrl: imageUrl,
              selectImageModal: false,
            });
          }}
        />
      </div>
    );
  }

  goBack = () => {
    this.props.history.push("/modules/building/news/");
  };

  _deleteInformationItem = () => {
    // ensure we are in edit mode
    let errors = [];
    if (this.state.editMode) {
      if (this.state.editModeKey.length > 0) {
        deleteNewsItem(
          this.state.editModeKey
        ).then(
          () => {
            NotificationManager.success("Information Item Deleted");
            this.props.history.push("/modules/building/news/");

            ReactGA.event({
              category: "Digital Sign",
              action: "Delete Information Item",
            });
          },
          (error) => {
            errors.push(error);
            this.setState({ processing: false, errors: errors });
          }
        );
      }
    }
  };

  _onSaveItem = (event) => {
    let payload = {
      title: this.state.title,
      imageUrl: this.state.imageUrl || "",
      description: this.state.description,
      published_mobile: this.state.published_mobile,
      ds_image_only: this.state.ds_image_only,
      tags: this.state.selectedTags,
    };

    // validation
    let errors = [];
    if (this.state.title == null || this.state.title.trim().length === 0) {
      errors.push("Title is required");
    }

    if (
      this.state.description == null ||
      this.state.description.trim().length === 0
    ) {
      errors.push("Description is required");
    }

    if (errors.length === 0) {
      this.setState({ processing: true });

      if (this.state.editMode) {
        ReactGA.event({
          category: "Digital Sign",
          action: "Modify News & Information Item",
        });

        updateNewsItem(
          this.state.editModeKey,
          payload
        ).then(
          () => {
            NotificationManager.success("Information Item Updated");
            this.setState({ processing: false, errors: [] });
          },
          (error) => {
            errors.push(error);
            this.setState({ processing: false, errors: errors });
          }
        );
      } else {
        ReactGA.event({
          category: "Digital Sign",
          action: "Create News & Information Item",
        });

        saveNewsItem(payload).then(
          () => {
            NotificationManager.success("New Information Item Created");
            this.props.history.push("/modules/building/news/");
          },
          (error) => {
            errors.push("An error occurred, unable to complete item");
            this.setState({ processing: false, errors: errors });
          }
        );
      }
    } else {
      this.setState({ errors: errors });
    }

    event.preventDefault();
  };
}

export default withRouter(connect(mapStateToProps)(NewsItemScreen));
