import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Nav, NavLink } from "reactstrap";

import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";

import "./../App.css";
import { ModuleMap } from "../modules/config";
import { useRecoilState, useRecoilValue } from "recoil";
import { NavigationBarIsOpen } from "../SharedState";
import { SelectedSiteState } from "../SharedState";
import { isMobile } from "react-device-detect";
import { Transition } from "react-transition-group";

const SideBarWrapper = (props) => {
  if (isMobile) {
    return (
      <Transition in={props.isOpen} timeout={0}>
        {(state) => <div className={`fade-${state}`}>{props.children}</div>}
      </Transition>
    );
  }
  return props.children;
};

const LoggedInSideBarView = () => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);
  const [isNavBarOpen, setIsNavBarOpen] = useRecoilState(NavigationBarIsOpen);
  const selectedSite = useRecoilValue(SelectedSiteState);

  const [modules, setModules] = useState([]);

  useEffect(() => {
    if (selectedSite) {
      const role = authentication.user.access[selectedSite.domain][selectedSite.site].role ?? "resident";
      const permissions = authentication.user.access[selectedSite.domain][selectedSite.site].permissions ?? [];

      let modules = [];
      // TODO determine which building we are looking at
      const bIsAdmin = role?.toLowerCase() === "admin";
      for (var supportedModuleKey in configuration.data.modules) {
        const bEnabled = configuration.data.modules[supportedModuleKey].enabled;
        const order = configuration.data.modules[supportedModuleKey].order;
        const bHasPermission = permissions.includes(supportedModuleKey);
        if (ModuleMap[supportedModuleKey] && bEnabled && (bHasPermission || bIsAdmin)) {
          modules.push({
            module: ModuleMap[supportedModuleKey].sidebar,
            order,
          });
        }
      }

      setModules(modules.sort(_sortOrder));
    }
  }, [selectedSite]);

  // if (modules.length === 0) {
  //   return null;
  // }

  return (
    <SideBarWrapper isOpen={isNavBarOpen}>
      <Nav navbar className="SideBar">
        {modules.map(({ module }) => {
          return (
            <div key={module.title} className="SideBarModule">
              <div
                className="SideBarHeader"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {module.icon ? (
                  React.createElement(module.icon, {
                    style: { marginLeft: 10, marginRight: 10 },
                  })
                ) : (
                  <span style={{ width: 20 }} />
                )}
                <h1>{module.title}</h1>
              </div>
              <div className="SideBarModuleLinks">
                {module.items.map((item) => {
                  return (
                    <LineItem key={item.path} show={true}>
                      <LinkContainer
                        to={item.path}
                        style={{ color: "#FFFFFF", padding: 0, paddingLeft: 5 }}
                        activeStyle={{
                          backgroundColor: "rgba(256, 256, 256, 0.14)",
                        }}
                      >
                        <NavLink onClick={() => setTimeout(() => setIsNavBarOpen(false), 500)}>{item.title}</NavLink>
                      </LinkContainer>
                    </LineItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Nav>
    </SideBarWrapper>
  );
};

function LineItem(props) {
  if (props.show === true) {
    return props.children;
  } else {
    return null;
  }
}

function _sortOrder(a, b) {
  if (a.order < b.order) {
    return -1;
  } else if (a.order > b.order) {
    return 1;
  }
}

export default withRouter(LoggedInSideBarView);
