const initialState = {
  currentState: "READY",
  data: {
    id: null,
    modules: {},
    permissions: {},
    sitenames: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONFIG_FETCHING": {
      return {
        currentState: "FETCHING",
        data: {
          ...state.data,
        },
      };
    }
    case "CONFIG_SUCCESS": {
      return {
        currentState: "SUCCESS",
        data: {
          id: action.site,
          domain: action.domain,
          modules: getSupportedModules(action.payload),
          permissions: getPermissionDescriptions(action.payload),
          sitenames: getSiteNames(action.payload),
        },
      };
    }
    case "CONFIG_FAILURE": {
      return {
        currentState: "ERROR",
        data: {
          id: null,
          modules: {},
        },
      };
    }
    default: {
      return state;
    }
  }
};

function getSiteNames(payload) {
  const sitesArr = payload.sites;
  let sites = {};
  sitesArr.forEach((item) => {
    sites[item.id] = item.name;
  });
  return sites;
}

function getSupportedModules(payload) {
  const modules = {};
  Object.keys(payload.modules).forEach((module) => {
    modules[module] = {
      enabled: payload.modules[module]?.enabled,
      order: payload.modules[module]?.order,
      flags: {},
    };
  });
  return modules;
}

function getPermissionDescriptions(payload) {
  const permissions = {};
  Object.keys(payload.permissions).forEach((permission) => {
    permissions[permission] = {
      title: payload.permissions[permission]?.label,
      description: payload.permissions[permission]?.description,
    };
  });
  return permissions;
}
