import React from "react";
import UserProfileScreen from "./view/UserProfileScreen";
import UserInvitationsScreen from "./view/UserInvitationsScreen";
import ProfileIcon from "../../../components/icons/ProfileIcon";

const config = {
  sidebar: {
    title: "Account",
    icon: ProfileIcon,
    items: [
      {
        title: "User Profile",
        path: "/user/profile",
      },
      {
        title: "Invitations",
        path: "/user/invitations",
      },
    ],
  },
  routes: [
    {
      path: "/user/invitations",
      main: () => <UserInvitationsScreen />,
    },
    {
      path: "/user/profile",
      main: () => <UserProfileScreen />,
    },
  ],
};

export default config;
