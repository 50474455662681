import React from "react";
import ModuleHeader from "../../../components/ModuleHeader";
import { withRouter } from "react-router-dom";

const GuestRegistrationScreen = ({ history }) => {
  return (
    <>
      <ModuleHeader title="Concierge Services" subTitle="Guest Registration" />
    </>
  );
};

export default withRouter(GuestRegistrationScreen);
