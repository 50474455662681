import React from "react";
import AppManagementIcon from "./AppIcon";
import UserManagementScreen from "./view/UserManagementScreen";
import ServicesScreen from "./view/ServicesScreen";
import { ServiceSettingsScreen } from "./view/ServiceSettingsScreen";

const config = {
  sidebar: {
    title: "App Management",
    icon: AppManagementIcon,
    items: [
      {
        title: "Residents",
        path: "/modules/app/users",
      },
      {
        title: "Services & Information",
        path: "/modules/app/services",
      },
    ],
  },
  routes: [
    {
      path: "/modules/app/users",
      main: () => <UserManagementScreen />,
    },
    {
      path: "/modules/app/services/settings/:parentId/:id",
      main: () => <ServiceSettingsScreen />,
    },
    {
      path: "/modules/app/services/settings/:id",
      main: () => <ServiceSettingsScreen />,
    },
    {
      path: "/modules/app/services",
      main: () => <ServicesScreen />,
    },
  ],
};

export default config;
