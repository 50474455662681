import React from "react";
import { connect } from "react-redux";
import { Button, Input, Label, ListGroupItem, ListGroup } from "reactstrap";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import {
  updateInformationItemPositionByIndex,
  updateContactItemPositionByIndex,
} from "../action";
import {
  fetchInformationAndContacts,
  addInformationItem,
  updateInformationItem,
  deleteInformationItem,
  deleteContact,
  updateContact,
  addContact,
} from "../action/infomation";
import LoadingScreen from "../../../screens/LoadingScreen";
import ModuleSettingsModal from "./component/ModuleSettingsModal";
import { SimpleEditor } from "../../../components/SimpleEditor";
import { NotificationManager } from "react-notifications";
import ModuleHeader from "../../../components/ModuleHeader";

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

class InformationModuleSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sortedInformationItems: [],
      sortedContactItems: [],
      infoModal: false,
      infoModalIndex: -1,
      contactModal: false,
      contactModalIndex: -1,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.refresh();
  }

  refresh = () => {
    fetchInformationAndContacts()
      .then((settings) => {
        this.setState({
          sortedInformationItems: settings.infoItems,
          sortedContactItems: settings.contactItems,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          sortedInformationItems: [],
          sortedContactItems: [],
          loading: false,
        });
      });
  };

  render() {
    return (
      <div style={{ flex: 1 }}>
        <ModuleHeader title="Building" subTitle="Information & Contacts" />
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }} />
          <Button color="secondary" onClick={this.createInfoItem} size="sm">
            Create Information Item
          </Button>
        </div>
        <div style={{ marginTop: 10, marginBottom: 50 }}>
          <SortableList
            useDragHandle={true}
            onSortEnd={this.onInformationSortEnd}
          >
            <ListGroup>
              <ListGroupItem
                disabled
                style={{
                  backgroundColor: "#e8ecef",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <div
                  className="ListHeader"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ width: 50 }} />
                  <div style={{ flex: 1 }}>Information Item</div>
                </div>
              </ListGroupItem>
              {this.state.sortedInformationItems.length === 0 ? (
                this.state.loading ? (
                  <div style={{ marginTop: 10 }}>
                    <LoadingScreen />
                  </div>
                ) : (
                  <div className="ListEmpty">There are no entries here</div>
                )
              ) : (
                this.state.sortedInformationItems.map((value, index) => (
                  <SortableItem key={`info-item-${index}`} index={index}>
                    <ListGroupItem
                      action
                      onClick={this.onInformationItemClick(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <DragHandle />
                        <div
                          className="ActiveCollapseItem"
                          style={{ flex: 1, justifyContent: "center" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h2 style={{ padding: 0, margin: 0 }}>
                              {value.title}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </SortableItem>
                ))
              )}
            </ListGroup>
          </SortableList>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }} />
          <Button
            color="secondary"
            onClick={this.createBuildingContact}
            size="sm"
          >
            Create Building Contact
          </Button>
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <SortableList useDragHandle={true} onSortEnd={this.onContactSortEnd}>
            <ListGroup>
              <ListGroupItem
                disabled
                style={{
                  backgroundColor: "#e8ecef",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <div
                  className="ListHeader"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ width: 50 }} />
                  <div style={{ flex: 1 }}>Contact</div>
                  <div style={{ flex: 1 }}>Name</div>
                  <div style={{ flex: 1 }}>Number</div>
                </div>
              </ListGroupItem>
              {this.state.sortedContactItems.length === 0 ? (
                this.state.loading ? (
                  <div style={{ marginTop: 10 }}>
                    <LoadingScreen />
                  </div>
                ) : (
                  <div className="ListEmpty">There are no entries here</div>
                )
              ) : (
                this.state.sortedContactItems.map((value, index) => (
                  <SortableItem key={`list-item-${index}`} index={index}>
                    <ListGroupItem
                      action
                      onClick={this.onContactClick(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <DragHandle />
                        <div
                          className="ActiveCollapseItem"
                          style={{ flex: 1, justifyContent: "center" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ flex: 1 }}>
                              <h2 style={{ padding: 0, margin: 0 }}>
                                {value.contact}
                              </h2>
                            </div>
                            <div style={{ flex: 1 }}>
                              <h2 style={{ padding: 0, margin: 0 }}>
                                {value.name}
                              </h2>
                            </div>
                            <div style={{ flex: 1 }}>
                              <h2 style={{ padding: 0, margin: 0 }}>
                                {value.phone}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </SortableItem>
                ))
              )}
            </ListGroup>
          </SortableList>
        </div>
        {this.renderModal()}
      </div>
    );
  }

  onInformationSortEnd = ({ oldIndex, newIndex }) => {
    let newSortedItems = arrayMove(
      this.state.sortedInformationItems,
      oldIndex,
      newIndex
    );

    this.setState({
      sortedInformationItems: newSortedItems,
    });

    // update the backend with new sort order
    let posObject = {};
    newSortedItems.map((item, index) => {
      posObject[item.id] = { position: index };
      return true;
    });
    updateInformationItemPositionByIndex(
      this.props.configuration.data.id,
      posObject
    );
  };

  onContactSortEnd = ({ oldIndex, newIndex }) => {
    let newSortedItems = arrayMove(
      this.state.sortedContactItems,
      oldIndex,
      newIndex
    );

    this.setState({
      sortedContactItems: newSortedItems,
    });

    // update the backend with new sort order
    let posObject = {};
    newSortedItems.map((item, index) => {
      posObject[item.id] = { position: index };
      return true;
    });
    updateContactItemPositionByIndex(
      this.props.configuration.data.id,
      posObject
    );
  };

  renderModal = () => {
    if (this.state.infoModal) {
      return (
        <InfoItemModal
          active={this.state.infoModal}
          onCancel={() =>
            this.setState({ infoModal: false, infoModalIndex: -1 })
          }
          onCreate={this.onInformationItemCreate}
          onModify={this.onInformationItemModify}
          onDelete={this.onInformationItemDelete}
          item={
            this.state.infoModalIndex > -1
              ? this.state.sortedInformationItems[this.state.infoModalIndex]
              : { create: true }
          }
        />
      );
    }

    if (this.state.contactModal) {
      return (
        <BuildingContactModal
          active={this.state.contactModal}
          onCancel={() =>
            this.setState({ contactModal: false, contactModalIndex: -1 })
          }
          onCreate={this.onContactItemCreate}
          onModify={this.onContactItemModify}
          onDelete={this.onContactItemDelete}
          item={
            this.state.contactModalIndex > -1
              ? this.state.sortedContactItems[this.state.contactModalIndex]
              : { create: true }
          }
        />
      );
    }

    return null;
  };

  createInfoItem = () => {
    this.setState({ infoModal: true });
  };

  onInformationItemClick = (index) => () => {
    this.setState({ infoModal: true, infoModalIndex: index });
  };

  onInformationItemCreate = (data) => {
    addInformationItem(data)
      .then(() => {
        NotificationManager.success("Added Information Item");

        // close modal
        this.setState({ infoModal: false, infoModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error adding Notification Item");
      });
  };

  onInformationItemModify = (id, data) => {
    updateInformationItem(id, data)
      .then(() => {
        NotificationManager.success("Updated Information Item");

        // close modal
        this.setState({ infoModal: false, infoModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error updating Notification Item");
      });
  };

  onInformationItemDelete = (id) => {
    deleteInformationItem(id)
      .then(() => {
        NotificationManager.success("Deleted Information Item");

        // close modal
        this.setState({ infoModal: false, infoModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error deleting Information Item");
      });
  };

  createBuildingContact = () => {
    this.setState({ contactModal: true });
  };

  onContactClick = (index) => () => {
    this.setState({ contactModal: true, contactModalIndex: index });
  };

  onContactItemCreate = (contact) => {
    addContact(contact)
      .then(() => {
        NotificationManager.success("Added Information Item");

        // close modal
        this.setState({ contactModal: false, contactModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error adding Building Contact");
      });
  };

  onContactItemModify = (id, contact) => {
    updateContact(id, contact)
      .then(() => {
        NotificationManager.success("Updated Information Item");

        // close modal
        this.setState({ contactModal: false, contactModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error updating Building Contact");
      });
  };

  onContactItemDelete = (id) => {
    deleteContact(id)
      .then(() => {
        NotificationManager.success("Deleted Building Contact");

        // close modal
        this.setState({ contactModal: false, contactModalIndex: -1 });

        // refresh data
        this.refresh();
      })
      .catch(() => {
        NotificationManager.error("Error deleting Building Contact");
      });
  };
}

class InfoItemModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      createMode: props.item.create,
      id: props.item.id || null,
      title: props.item.title || "",
      content: props.item.content || "",
    };
  }

  render() {
    const title = this.state.createMode
      ? "Create Information Item"
      : "Modify Information Item";
    return (
      <ModuleSettingsModal
        active={this.props.active}
        onCancel={() => this.props.onCancel()}
        onCreate={this.onCreate}
        onDelete={this.onDelete}
        title={title}
        confirmColor="success"
        cancelColor="secondary"
        deleteColor="danger"
        showDelete={!this.state.createMode}
      >
        <Label>Title</Label>
        <Input
          defaultValue={this.state.title}
          onChange={(event) => this.setState({ title: event.target.value })}
        />
        <Label style={{ marginTop: 10 }}>Content</Label>
        <SimpleEditor
          hasLists
          value={this.state.content}
          onChange={(value) => this.setState({ content: value })}
          disabled={false}
        />
      </ModuleSettingsModal>
    );
  }

  onDelete = () => {
    if (this.state.id && this.props.onDelete) {
      this.props.onDelete(this.state.id);
    }
  };

  onCreate = () => {
    // validate
    if (this.state.title.length === 0) {
      NotificationManager.error("Title is required");
      return;
    }

    if (this.state.content.length === 0) {
      NotificationManager.error("Content is required");
      return;
    }

    if (this.state.createMode && this.props.onCreate) {
      this.props.onCreate({
        title: this.state.title,
        content: this.state.content,
      });
    } else if (!this.state.createMode && this.props.onModify) {
      this.props.onModify(this.state.id, {
        title: this.state.title,
        content: this.state.content,
      });
    }
  };
}

class BuildingContactModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      createMode: props.item.create,
      id: props.item.id || null,
      name: props.item.name || "",
      contact: props.item.contact || "",
      phone: props.item.phone || "",
    };
  }

  render() {
    const title = this.state.createMode
      ? "Create Building Contact"
      : "Modify Building Contact";
    return (
      <ModuleSettingsModal
        active={this.props.active}
        onCancel={() => this.props.onCancel()}
        onCreate={this.onCreate}
        onDelete={this.onDelete}
        title={title}
        confirmColor="success"
        cancelColor="secondary"
        deleteColor="danger"
        showDelete={!this.state.createMode}
      >
        <Label>Contact</Label>
        <Input
          defaultValue={this.state.contact}
          onChange={(event) => this.setState({ contact: event.target.value })}
        />
        <Label style={{ marginTop: 10 }}>Name</Label>
        <Input
          defaultValue={this.state.name}
          onChange={(event) => this.setState({ name: event.target.value })}
        />
        <Label style={{ marginTop: 10 }}>Phone</Label>
        <Input
          defaultValue={this.state.phone}
          onChange={(event) => this.setState({ phone: event.target.value })}
        />
      </ModuleSettingsModal>
    );
  }

  onDelete = () => {
    if (this.state.id && this.props.onDelete) {
      this.props.onDelete(this.state.id);
    }
  };

  onCreate = () => {
    // validate
    if (this.state.contact.length === 0) {
      NotificationManager.error("Contact is required");
      return;
    }

    if (this.state.name.length === 0) {
      NotificationManager.error("Name is required");
      return;
    }

    if (this.state.phone.length === 0) {
      NotificationManager.error("Phone is required");
      return;
    }

    if (this.state.createMode && this.props.onCreate) {
      this.props.onCreate({
        contact: this.state.contact,
        name: this.state.name,
        phone: this.state.phone,
      });
    } else if (!this.state.createMode && this.props.onModify) {
      this.props.onModify(this.state.id, {
        contact: this.state.contact,
        name: this.state.name,
        phone: this.state.phone,
      });
    }
  };
}

const DragHandle = SortableHandle(() => (
  <img
    alt=""
    src={require("./../../../img/sort.png")}
    style={{ height: 20, width: 30, marginRight: 20 }}
  />
));

const SortableItem = SortableElement(({ children }) => {
  return children;
});

const SortableList = SortableContainer(({ children }) => {
  return children;
});

export default connect(mapStateToProps)(InformationModuleSettings);
