import React, { useCallback, useEffect, useState } from "react";
import ModuleHeader from "../../../components/ModuleHeader";
import LoadingScreen from "../../../screens/LoadingScreen";

import { createTag, fetchTags, removeTag } from "../action/settings";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { TagItem, CreateTag } from "./component/Tag";
import Toggle from "react-toggle";
import {
  fetchNotifications,
  updateNotificationSetting,
} from "../action/settings";

const BuildingSettings = () => {
  const [tags, setTags] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotificaitons] = useState(false);
  const configuration = useSelector((state) => state.configuration);

  const refreshTags = useCallback(async () => {
    try {
      const tags = await fetchTags();
      setTags(tags);
    } catch {
      console.error("Error retrieving tags");
    }
  }, [configuration.data.id]);

  const refreshNotifications = useCallback(async () => {
    try {
      setLoadingNotificaitons(true);
      const notifications = await fetchNotifications();
      setNotifications(notifications);
    } catch {
      console.log("Error retrieving notifications");
    }
    setLoadingNotificaitons(false);
  }, [configuration.data.id]);

  const updateNotification = useCallback(
    async (id, toggled) => {
      try {
        await updateNotificationSetting(id, toggled);
        NotificationManager.success("Updated Notification Setting");
      } catch {
        NotificationManager.error("Unable to update Notification Setting");
      }
    },
    [configuration.data.id]
  );

  useEffect(() => {
    refreshTags();
  }, [refreshTags]);
  useEffect(() => {
    refreshNotifications();
  }, [refreshNotifications]);

  const onCreateTag = (hexValue, title) => {
    createTag(title, hexValue)
      .then(() => {
        refreshTags();
        NotificationManager.success("Tag was created");
      })
      .catch(() => {
        NotificationManager.error("Tag was not created");
      });
  };

  const onRemoveTag = (id) => {
    removeTag(id)
      .then(() => {
        refreshTags();
        NotificationManager.success("Tag was removed");
      })
      .catch(() => {
        NotificationManager.error("Tag was not removed");
      });
  };

  return (
    <>
      <ModuleHeader title="Building" subTitle="Settings" />
      <div className="SettingsBody">
        <h1>General</h1>
        <div className="SettingsContainer">
          <div
            className="SettingsItem"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1>News & Events Tags</h1>
            <h2>Create or Remove News & Events tags.</h2>
            <CreateTag onCreateTag={onCreateTag} />
            <div
              style={{
                marginBottom: "5px",
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#F6F6F6",
                borderRadius: "5px",
              }}
            >
              {tags.map((tag) => (
                <div key={tag.id} style={{ marginBottom: 5 }}>
                  <TagItem
                    id={tag.id}
                    title={tag.title}
                    baseColor={tag.color}
                    onAction={onRemoveTag}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <h1>Notifications</h1>
        <div className="SettingsContainer">
          {loadingNotifications ? <LoadingScreen /> : null}
          {notifications.map((notification) => (
            <ToggleLineItem
              key={notification.id}
              title={notification.title}
              subtitle={notification.subtitle}
              toggleValue={notification.value}
              onToggled={(toggled) => {
                updateNotification(notification.id, toggled);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ToggleLineItem = ({ title, subtitle, toggleValue, onToggled }) => {
  return (
    <div
      className="SettingsItem"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <div style={{ display: "flex", alignContent: "center" }}>
          <Toggle
            icons={false}
            defaultChecked={toggleValue}
            onChange={(event) => {
              onToggled(event.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BuildingSettings;
