import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function fetchAllAgents() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/org/agents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const agents = [];
      data.agents.forEach((agent) => {
        agents.push({
          id: agent.uid,
          email: agent.email,
          permissions: agent.permissions ?? {},
          pending: false,
        });
      });
      resolve(agents);
    } catch {
      reject();
    }
  });
}

export function modifyAgent(id, permissions) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/org/agents/${id}`,
        {
          permissions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function inviteAgent(building, email, permissions) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/auth/invite`,
        {
          email,
          role: "agent",
          domain: "demo",
          building,
          permissions: permissions.join(","),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}
