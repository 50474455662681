import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label } from "reactstrap";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
    };
  }

  return "#000000"; // some default
}

const SelectableItems = ({ items, selected, onSelectedChanged }) => {
  const [selectedItems, setSelectedItems] = useState(selected);

  useEffect(() => {
    if (onSelectedChanged) {
      onSelectedChanged(selectedItems);
    }
  }, [selectedItems]);

  const onSelectionChange = (id) => (event) => {
    if (event.target.checked) {
      setSelectedItems((selectedItems) => selectedItems.concat([id]));
    } else {
      setSelectedItems((selectedItems) =>
        selectedItems.filter((value) => {
          return value !== id;
        })
      );
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
      }}
    >
      <div
        style={{
          id: "selectable_items",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          border: "solid 2px black",
        }}
      >
        {items.map((item) => {
          const baseColor = hexToRgbA(item.color);
          return (
            <FormGroup
              key={item.id}
              check
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: `rgba(${baseColor.r}, ${baseColor.g}, ${baseColor.b}, 0.14)`,
              }}
            >
              <Label
                check
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: 10,

                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Input
                  type="checkbox"
                  checked={selectedItems.includes(item.id)}
                  onChange={onSelectionChange(item.id)}
                />
                <span
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    color: `rgba(${baseColor.r}, ${baseColor.g}, ${baseColor.b}, 1)`,
                  }}
                >
                  {item.title}
                </span>
              </Label>
            </FormGroup>
          );
        })}
      </div>
    </div>
  );
};

export default SelectableItems;
