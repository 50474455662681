import React, { useEffect, useCallback, useState } from "react";
import { SketchPicker } from "react-color";
import CloseLightIcon from "../../../../components/icons/CloseLightIcon";
import AutosizeInput from "react-input-autosize";
import { Button } from "reactstrap";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
    };
  }

  return "#000000"; // some default
}

function generateColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

const CreateTag = ({ onCreateTag }) => {
  const [newBadge, setNewBadge] = useState("");
  const [color, setColor] = useState({ rgb: {}, hex: null });
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleClick = useCallback((event) => {
    let picker = document.getElementById(`create_color_picker`);
    if (picker && !picker.contains(event.target)) {
      onPickerClick();
    }
  }, []);

  useEffect(() => {
    const generatedHex = generateColor();
    setColor({ rgb: hexToRgbA(generatedHex), hex: generatedHex });
  }, [setColor]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  const onPickerClick = () => {
    setPickerOpen((state) => !state);
  };

  const onPickerChange = (value) => {
    setColor({ rgb: value.rgb, hex: value.hex });
  };

  const onCreate = () => {
    if (onCreateTag) {
      onCreateTag(color.hex, newBadge);

      // reset everything
      setNewBadge("");

      const generatedHex = generateColor();
      setColor({ rgb: hexToRgbA(generatedHex), hex: generatedHex });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div>
        <div
          style={{
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${
              color.rgb.b
            }, 1)`,
            marginRight: 10,
          }}
          onClick={onPickerClick}
        />
        {pickerOpen ? (
          <div
            id={`create_color_picker`}
            style={{
              position: "absolute",
              zIndex: 2,
              marginTop: 0,
            }}
          >
            <SketchPicker color={color} onChange={onPickerChange} />
          </div>
        ) : null}
      </div>
      <AutosizeInput
        placeholder="Tag Name"
        value={newBadge}
        onChange={(event) => setNewBadge(event.target.value)}
        inputStyle={{
          minWidth: 120,
          backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${
            color.rgb.b
          }, 0.14)`,
          color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, 1)`,
          fontWeight: "bold",
          padding: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 10,
          outline: "none",
          border: "none",
          textAlign: "center",
        }}
        style={{
          marginRight: 10,
        }}
      />

      <Button onClick={onCreate} style={{ height: 40 }}>
        Create
      </Button>
    </div>
  );
};

const TagItem = (props) => {
  const [color] = useState(hexToRgbA(props.baseColor));

  const onClose = () => {
    if (props.onAction && props.id) {
      props.onAction(props.id);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        className="BasicTag"
        style={{
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.14)`,
          marginRight: 5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            flex: 1,
            marginLeft: 5,
            marginRight: 5,
            color: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
            fontWeight: "bold",
          }}
        >
          {props.title}
        </span>
        {props.onAction ? (
          <CloseLightIcon
            style={{ marginRight: 5, marginLeft: 5 }}
            onClick={onClose}
          />
        ) : null}
      </div>
    </div>
  );
};

export { TagItem, CreateTag };
