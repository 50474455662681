import React from "react";

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: props.initialValue == null ? "" : props.initialValue,
      progress: "Click to Select Image",
    };
  }

  _uploadImage = () => {
    if (this.props.onUploadImage) {
      this.props.onUploadImage();
    }
  };

  renderUploader = (width, height) => {
    return (
      <label
        style={{
          display: "flex",
          backgroundColor: "#e9ecef",
          color: "white",
          padding: 10,
          borderRadius: 10,
          cursor: "pointer",
          height: height,
          width: width,
        }}
      >
        <div
          onClick={() => this._uploadImage()}
          className="ImageSelector"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            borderRadius: 10,
            backgroundColor: "#FFF",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ color: "#e9ecef" }}>{this.state.progress}</h1>
        </div>
      </label>
    );
  };

  renderSetImage = (width, height) => {
    if (this.props.imageUrl && this.props.imageUrl.length > 0) {
      return (
        <label
          style={{ cursor: "pointer" }}
          onClick={() => this._uploadImage()}
        >
          <img
            alt=""
            style={{ height: height, width: width, objectFit: "cover" }}
            src={this.props.imageUrl}
            onError={() => {
              this.setState({
                progress: "Error displaying image. Click to upload new image",
              });
            }}
          />
        </label>
      );
    } else {
      return this.renderUploader(width, height);
    }
  };

  render() {
    const { width } = this.props;
    if (this.props.imageUrl && this.props.imageUrl.length > 0) {
      return this.renderSetImage(width, width * 0.4167);
    } else {
      return this.renderUploader(width, width * 0.4167);
    }
  }
}

export default ImageUploader;
