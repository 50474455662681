import { atom } from "recoil";

export const NavigationBarIsOpen = atom({
  key: "navBarState",
  default: false,
});

export const ProfileBoxIsOpen = atom({
  key: "profileBoxOpenState",
  default: false,
});

export const SelectedSiteState = atom({
  key: "selectedSiteState",
  default: null,
});
