import React, { useEffect, useState } from "react";
import ModuleHeader from "../../../components/ModuleHeader";
import LoadingScreen from "../../../screens/LoadingScreen";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { withRouter } from "react-router-dom";
import Gravatar from "react-gravatar";
import { fetchAllMaintenanceRequests } from "../action";
import { useSelector } from "react-redux";

const MaintenanceItemsScreen = ({ history }) => {
  const [openRequests, setOpenRequests] = useState([]);
  const [ongoingRequests, setOngoingRequests] = useState([]);
  const [resolvedRequests, setResolvedRequests] = useState([]);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);

  const configuration = useSelector((state) => state.configuration);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        console.log("FETCHING");
        const requests = await fetchAllMaintenanceRequests();
        // clean this up
        setOpenRequests(requests.open);
        setOngoingRequests(requests.ongoing);
        setResolvedRequests(requests.resolved);
        setReady(true);
      } catch {
        setError("Error retrieving items");
        setOpenRequests([]);
        setOngoingRequests([]);
        setResolvedRequests([]);
        setReady(true);
      }
    };
    fetchItems();
  }, []);

  const onSelectItem = (id) => () => {
    history.push(`/modules/concierge/maintenance/${id}`);
  };

  const MaintenanceListItems = ({ header, loading, requests }) => {
    const getUrgencyColor = (urgency) => {
      if (urgency === "high") {
        return "#FF6437";
      } else if (urgency === "medium") {
        return "#FFB800";
      }
      return "#2CDEC3";
    };

    const ListItems = () => {
      if (loading) {
        return (
          <div style={{ margin: 20 }}>
            <LoadingScreen />
          </div>
        );
      }

      if (!requests || requests.length < 1) {
        return null;
      }
      return (
        <>
          {requests.map((request) => {
            return (
              <ListGroupItem
                key={request.id}
                action
                style={{ cursor: "pointer", padding: 0 }}
                onClick={onSelectItem(request.id)}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      padding: 0,
                      margin: 0,
                      backgroundColor: getUrgencyColor(request.urgency),
                      width: "5px",
                    }}
                  />
                  <div
                    style={{
                      padding: 10,
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    {request.area} issue in {request.room}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                      }}
                    >
                      <Gravatar
                        style={{ borderRadius: 5 }}
                        email={request.user.email}
                        size={16}
                        rating="pg"
                        default="monsterid"
                        className="CustomAvatar-image"
                      />
                      <span
                        style={{
                          fontSize: "0.75em",
                          fontWeight: "200",
                          marginLeft: 5,
                          flex: 1,
                        }}
                      >
                        {request.user.name} - Room {request.user.room}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    {request.unreadCount > 0 ? (
                      <Badge>{request.unreadCount}</Badge>
                    ) : null}
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
        </>
      );
    };

    return (
      <div style={{ marginBottom: 20 }}>
        <ListGroup>
          <ListGroupItem
            disabled
            style={{
              backgroundColor: "#e8ecef",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div
              className="ListHeader"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ flex: 1 }}>{header}</div>
            </div>
          </ListGroupItem>
          <ListItems />
        </ListGroup>
      </div>
    );
  };

  return (
    <>
      <ModuleHeader
        title="Concierge Services"
        subTitle="Maintenance Requests"
      />
      {!ready ? (
        <LoadingScreen />
      ) : (
        <>
          {error ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className="error">{error}</span>
            </div>
          ) : (
            <>
              <MaintenanceListItems
                header="Open"
                loading={!ready}
                requests={openRequests}
              />
              <MaintenanceListItems
                header="In-Progress"
                loading={!ready}
                requests={ongoingRequests}
              />
              <MaintenanceListItems
                header="Resolved"
                loading={!ready}
                requests={resolvedRequests}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(MaintenanceItemsScreen);
