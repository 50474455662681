import React from "react";

const SiteIcon = (props) => (
  <svg width={20} height={20} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M4.105 18.947c-1.345 0-2.445-1.015-2.522-2.296l-.004-.144V7.56a.8.8 0 0 1 .242-.571l.083-.071 7.579-5.694a.867.867 0 0 1 .94-.062l.094.062 7.579 5.694c.176.132.289.325.318.535l.007.107v8.947c0 1.3-1.052 2.362-2.378 2.436l-.148.004h-3.369l-.105-.006-.103-.019a.853.853 0 0 1-.51-.363l.006.009a.79.79 0 0 1-.124-.34l-.006-.094v-6.507H8.316V18.1a.824.824 0 0 1-.743.842l-.097.005h-3.37zM10 2.896l-6.737 5.06v8.551c0 .417.325.761.744.808l.098.006 2.526-.001v-6.507c0-.417.326-.76.744-.808L7.474 10h5.052c.432 0 .788.314.837.719l.005.094v6.507h2.527a.832.832 0 0 0 .836-.718l.006-.095v-8.55L10 2.897z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SiteIcon;
