import store from "../store.js";
import axios from "axios";
import firebase from "firebase/app";

const fetchConfiguration = async (domainId, siteId) => {
  store.dispatch({
    type: "CONFIG_FETCHING",
    payload: {},
  });

  try {
    const token = await getFirebaseToken();
    const { data } = await axios.get(
      "https://api.happybuilding.app/a/building/config",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    store.dispatch({
      type: "CONFIG_SUCCESS",
      payload: data,
      site: siteId,
      domain: domainId,
    });
  } catch (error) {
    console.log(error);
    store.dispatch({
      type: "CONFIG_FAILURE",
      payload: {},
    });
  }
};

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export { fetchConfiguration };
