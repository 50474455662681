import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function fetchNewsItems() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/building/bulletins`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const newsItems = [];
      data.items.forEach((newsItem) => {
        // reduce tags to just IDs
        const tagArr = newsItem.tags ?? [];
        const tags = [];
        tagArr.forEach((tag) => {
          tags.push(tag.id);
        });

        newsItems.push({
          id: newsItem.id,
          title: newsItem.title,
          imageUrl: newsItem.image,
          description: newsItem.description,
          tags: tags,
          published_mobile: newsItem.published_mobile,
          position: newsItem.position,
        });
      });

      resolve(newsItems);
    } catch {
      reject();
    }
  });
}

export function saveNewsItem(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/a/building/bulletins`,
        {
          title: payload?.title,
          imageUrl: payload?.imageUrl,
          description: payload?.description,
          published_mobile: payload?.published_mobile,
          tags: payload?.tags,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      resolve();
    } catch {
      reject();
    }
  });
}

export function updateNewsItem(newsId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/building/bulletins/${newsId}`,
        {
          title: payload?.title,
          imageUrl: payload?.imageUrl,
          description: payload?.description,
          published_mobile: payload?.published_mobile,
          tags: payload?.tags,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteNewsItem(newsId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/building/bulletins/${newsId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      resolve();
    } catch {
      reject();
    }
  });
}
