import firebase from "firebase/app";
import "firebase/database";

export function updateNewsItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise((resolve, reject) => {
    resolve();
  });
}

export function updateInformationItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise((resolve, reject) => {
    resolve();
  });
}

export function updateContactItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise((resolve, reject) => {
    resolve();
  });
}
