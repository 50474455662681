import React, { useState } from "react";
import {
  DraftailEditor,
  BLOCK_TYPE,
  INLINE_STYLE,
  ENTITY_TYPE,
} from "draftail";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import {
  AtomicBlockUtils,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RichUtils,
} from "draft-js";
import { Button, Input, Label } from "reactstrap";
import LoadingScreen from "../screens/LoadingScreen";
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase/app";
import "firebase/storage";
import generateRandomID from "uuid/v4";

const generateRandomFilename = () => generateRandomID();

const SimpleEditor = ({ value, onChange, disabled }) => {
  const getInitialMarkdown = () => {
    const converted =
      value && value.trim().length === 0
        ? null
        : convertToRaw(
            stateFromMarkdown(value, { parserOptions: { atomicImages: true } })
          );
    return converted;
  };

  const saveContents = (content) => {
    if (content != null) {
      if (onChange) {
        const converted = stateToMarkdown(convertFromRaw(content));
        onChange(converted);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <DraftailEditor
        disabled={disabled}
        rawContentState={getInitialMarkdown()}
        onSave={saveContents}
        entityTypes={[
          {
            type: ENTITY_TYPE.LINK,
            icon: <LinkIcon />,
            source: EntityOverlay,
            decorator: LinkDecorator,
            attributes: ["url"],
            whitelist: {
              href: "^(?![#/])",
            },
          },
          {
            type: ENTITY_TYPE.IMAGE,
            icon: <ImageIcon />,
            source: EntityOverlay,
            block: ImageBlock,
            attributes: ["src", "alt"],
            whitelist: {
              src: "^(?!(data:|file:))",
            },
          },
        ]}
        blockTypes={[
          { type: BLOCK_TYPE.HEADER_ONE, icon: <TitleIcon /> },
          { type: BLOCK_TYPE.HEADER_TWO, icon: <SubtitleIcon /> },
          {
            type: BLOCK_TYPE.ORDERED_LIST_ITEM,
            icon: <NumberedListIcon />,
          },
          { type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <UnorderListIcon /> },
        ]}
        inlineStyles={[
          { type: INLINE_STYLE.BOLD, icon: <BoldIcon /> },
          { type: INLINE_STYLE.ITALIC, icon: <ItalicsIcon /> },
        ]}
      />
    </div>
  );
};

const EntityOverlay = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "auto",
      }}
    >
      <EntitySource {...props} />
    </div>
  );
};

const EntitySource = (props) => {
  if (props.entityType.type === ENTITY_TYPE.LINK) {
    return <LinkOverlay {...props} />;
  }

  if (props.entityType.type === ENTITY_TYPE.IMAGE) {
    return <ImageOverlay {...props} />;
  }
  return null;
};

const LinkOverlay = ({
  entity,
  editorState,
  entityType,
  onComplete,
  onClose,
}) => {
  const [url, setUrl] = useState(entity && entity.getData().url);
  const setAndClose = () => {
    const contentState = editorState.getCurrentContent();
    const mungedUrl = !url.startsWith("https://") ? "https://" + url : url;

    const data = { url: mungedUrl };
    const contentStateWithEntity = contentState.createEntity(
      entityType.type,
      "MUTABLE",
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const nextState = RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    );

    onComplete(nextState);
  };

  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        zIndex: 3,
        padding: 10,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span className="LinkSource__text">Link</span>
        <Input
          autoFocus
          placeholder="https://www.google.com"
          style={{ marginRight: 5 }}
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        <Button
          style={{ marginRight: 5 }}
          color="secondary"
          onClick={() => onClose()}
        >
          CANCEL
        </Button>
        <Button color="success" onClick={() => setAndClose()}>
          OK
        </Button>
      </div>
    </div>
  );
};

const LinkDecorator = ({ entityKey, children, onEdit, onRemove }) => {
  return (
    <>
      <div className="LinkDecoration" style={{ position: "relative" }}>
        <span
          className="LinkDecoration__text"
          onClick={() => onEdit(entityKey)}
        >
          {children}
        </span>
        <div
          className="LinkDelete"
          style={{
            position: "absolute",
            top: -10,
            right: -10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => onRemove(entityKey)}
        >
          <CloseIcon style={{ width: 8, height: 8 }} />
        </div>
      </div>
    </>
  );
};

const ImageOverlay = ({
  entity,
  editorState,
  entityType,
  onComplete,
  onClose,
  entityKey,
}) => {
  const [altText, setAltText] = useState(entity && entity.getData().alt);
  const [url, setUrl] = useState(entity && entity.getData().src);
  const [processing, setProcessing] = useState(false);

  const onUploadSuccess = (filename) => {
    firebase
      .storage()
      .ref("draft_editor_images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        setUrl(url);
        setProcessing(false);
      });
  };

  const setAndClose = () => {
    const content = editorState.getCurrentContent();
    let nextState;

    if (entity && entityKey) {
      const nextContent = content.mergeEntityData(entityKey, {
        src: url,
        alt: altText,
      });
      nextState = EditorState.push(editorState, nextContent, "apply-entity");
    } else {
      const contentWithEntity = content.createEntity(
        // Fixed in https://github.com/facebook/draft-js/commit/6ba124cf663b78c41afd6c361a67bd29724fa617, to be released.
        // $FlowFixMe
        entityType.type,
        "MUTABLE",
        {
          alt: altText,
          src: url,
        }
      );
      nextState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        contentWithEntity.getLastCreatedEntityKey(),
        " "
      );
    }

    onComplete(nextState);
  };

  return (
    <div
      style={{
        width: "80%",
        backgroundColor: "white",
        zIndex: 3,
        padding: 10,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Label
          className="SelectableButton"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 50,
          }}
        >
          {processing ? <LoadingScreen /> : `Select File to Upload`}
          <FileUploader
            hidden={true}
            filename={generateRandomFilename}
            accept="image/*"
            storageRef={firebase.storage().ref("draft_editor_images")}
            onUploadStart={() => setProcessing(true)}
            onUploadSuccess={onUploadSuccess}
          />
        </Label>

        {url ? <Input disabled={true} value={url} /> : null}
        <Input
          autoFocus
          placeholder="Description of Image"
          style={{ marginRight: 5, marginTop: 10, flex: 1 }}
          value={altText ? altText : ""}
          onChange={(e) => setAltText(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            style={{ marginRight: 5 }}
            color="secondary"
            onClick={() => onClose()}
          >
            CANCEL
          </Button>
          <Button color="success" onClick={() => setAndClose()}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

const ImageBlock = ({ blockProps }) => {
  const { entity, onEditEntity, entityKey } = blockProps;
  const { src, alt } = entity.getData();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        className="ImageBlock__image"
        src={src}
        alt=""
        width={256}
        onClick={() => onEditEntity(entityKey)}
      />
      <span className="ImageBlock__altText">{alt}</span>
    </div>
  );
};

const BoldIcon = (props) => (
  <svg width={17} height={28} viewBox="0 0 17 28" {...props}>
    <text
      fill="#FFF"
      fillRule="evenodd"
      fontFamily="HelveticaNeue-Bold, Helvetica Neue"
      fontSize={24}
      fontWeight="bold"
    >
      <tspan x={0} y={23}>
        {`B`}
      </tspan>
    </text>
  </svg>
);

const ItalicsIcon = (props) => (
  <svg width={7} height={28} viewBox="0 0 7 28" {...props}>
    <text
      fill="#FFF"
      fillRule="evenodd"
      fontFamily="HelveticaNeue-MediumItalic, Helvetica Neue"
      fontSize={24}
      fontStyle="italic"
      fontWeight={400}
    >
      <tspan x={0} y={23}>
        {`I`}
      </tspan>
    </text>
  </svg>
);

const NumberedListIcon = (props) => (
  <svg width={22} height={21} viewBox="0 0 22 21" {...props}>
    <g fill="none" fillRule="evenodd">
      <text
        fontFamily="HelveticaNeue-Medium, Helvetica Neue"
        fontSize={6}
        fontWeight={400}
        fill="#FFF"
      >
        <tspan x={0} y={6}>
          {`1`}
        </tspan>
      </text>
      <text
        fontFamily="HelveticaNeue-Medium, Helvetica Neue"
        fontSize={6}
        fontWeight={400}
        fill="#FFF"
      >
        <tspan x={0} y={13}>
          {`2`}
        </tspan>
      </text>
      <text
        fontFamily="HelveticaNeue-Medium, Helvetica Neue"
        fontSize={6}
        fontWeight={400}
        fill="#FFF"
      >
        <tspan x={0} y={20}>
          {`3`}
        </tspan>
      </text>
      <path
        stroke="#FFF"
        strokeWidth={3}
        strokeLinecap="square"
        d="M8 4h12M8 11h12M8 18h12"
      />
    </g>
  </svg>
);

const UnorderListIcon = (props) => (
  <svg width={23} height={18} viewBox="0 0 23 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#FFF"
        strokeWidth={3}
        strokeLinecap="square"
        d="M9 2h12M9 9h12M9 16h12"
      />
      <circle stroke="#FFF" fill="#FFF" cx={2} cy={2} r={1.5} />
      <circle stroke="#FFF" fill="#FFF" cx={2} cy={9} r={1.5} />
      <circle fill="#FFF" cx={2} cy={16} r={2} />
    </g>
  </svg>
);

const TitleIcon = (props) => (
  <svg width={48} height={28} viewBox=" 0 0 48 28" {...props}>
    <text
      fill="#FFF"
      fillRule="evenodd"
      fontFamily="HelveticaNeue-Medium, Helvetica Neue"
      fontSize={24}
      fontWeight={400}
    >
      <tspan x={0} y={23}>
        {`Title`}
      </tspan>
    </text>
  </svg>
);

const SubtitleIcon = (props) => (
  <svg width={50} height={17} viewBox="0 0 50 17" {...props}>
    <text
      fill="#FFF"
      fillRule="evenodd"
      fontFamily="HelveticaNeue-Medium, Helvetica Neue"
      fontSize={14}
      fontWeight={400}
    >
      <tspan x={0} y={14}>
        {`Subtitle`}
      </tspan>
    </text>
  </svg>
);

const LinkIcon = (props) => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#a)" fill="none" fillRule="evenodd">
      <g fill={props.color ? props.color : "#000"} fillRule="nonzero">
        <path d="M8.93 16.897l-2.538 2.528a2.698 2.698 0 0 1-3.808 0 2.675 2.675 0 0 1 0-3.793l5.077-5.058a2.699 2.699 0 0 1 3.807 0 .9.9 0 0 0 1.27 0 .892.892 0 0 0 0-1.265 4.499 4.499 0 0 0-6.346 0l-5.078 5.059a4.458 4.458 0 0 0 0 6.322 4.498 4.498 0 0 0 6.347 0l2.538-2.529a.892.892 0 0 0 0-1.264.9.9 0 0 0-1.27 0z" />
        <path d="M20.638 1.264c-1.817-1.685-4.762-1.685-6.578 0l-3.157 2.928a.82.82 0 0 0 0 1.22.982.982 0 0 0 1.316 0l3.156-2.928c1.09-1.01 2.857-1.01 3.947 0 1.09 1.012 1.09 2.65 0 3.661l-5.788 5.37c-1.09 1.012-2.856 1.012-3.946 0a.982.982 0 0 0-1.316 0 .82.82 0 0 0 0 1.221c1.817 1.685 4.761 1.685 6.578 0l5.788-5.37c1.816-1.685 1.816-4.417 0-6.102z" />
      </g>
    </g>
  </svg>
);

const ImageIcon = (props) => (
  <svg width={25} height={20} viewBox="0 0 25 20" {...props}>
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#a)" fill="none" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <path d="M0 20h25V0H0v20zM1.563 1.574h21.875v16.852H1.563V1.574z" />
        <path d="M10.838 11.24L8.925 8.97 3 16h19l-7.587-9-3.575 4.24zM8.6 14.335H6.55l2.375-2.82 2.378 2.82H8.599zm4.848 0l-1.536-1.823 2.502-2.967 4.038 4.79h-5.004zM11 8c1.103 0 2-1.122 2-2.5 0-1.379-.897-2.5-2-2.5S9 4.121 9 5.5 9.897 8 11 8zm0-3.051c.245 0 .444.247.444.55 0 .304-.199.55-.444.55s-.444-.246-.444-.55c0-.303.199-.55.444-.55z" />
      </g>
    </g>
  </svg>
);

const CloseIcon = (props) => (
  <svg width={10} height={10} viewBox="0 0 14 14" {...props}>
    <path
      d="M1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.497 1.32l-.083.094L8.415 7l5.292 5.293a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.415l-5.293 5.292a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .293 1.707A1 1 0 0 1 1.613.21z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export { SimpleEditor };
