import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";

export function fetchAllMaintenanceRequests() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get(
            "https://api.happybuilding.app/a/concierge/maintenance/request/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              return resolve(response.data);
            }
            return reject();
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function updateMaintenanceRequestStateById(id, state) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .patch(
            `https://api.happybuilding.app/a/concierge/maintenance/request/${id}`,
            {
              state: state,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
}

export function fetchMaintenanceRequestById(id) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get(
            `https://api.happybuilding.app/a/concierge/maintenance/request/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              return resolve(response.data.item);
            }
            return reject();
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function fetchRequestMessages(requestId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const requestRes = await axios.get(
        `https://api.happybuilding.app/a/concierge/maintenance/request/messages/${requestId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const messages = [];
      const allMessages = requestRes.data.messages;
      allMessages.forEach((message) => {
        messages.push({
          id: message.id,
          requestId: message.requestId,
          type: message.type,
          timestamp: message.timestamp,
          message: message.message,
        });
      });
      resolve(messages);
    } catch {
      reject();
    }
  });
}

export function fetchAllOtherRequests() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get("https://api.happybuilding.app/a/concierge/other/request/", 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data) {
              return resolve(response.data);
            }
            return reject();
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function updateOtherRequestStateById(id, state) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .patch(
            `https://api.happybuilding.app/a/concierge/other/request/${id}`,
            {
              state: state,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
}

export function fetchOtherRequestById(id) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get(
            `https://api.happybuilding.app/a/concierge/other/request/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              return resolve(response.data.item);
            }
            return reject();
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function fetchOtherRequestMessages(requestId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const requestRes = await axios.get(
        `https://api.happybuilding.app/a/concierge/other/request/messages/${requestId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const messages = [];
      const allMessages = requestRes.data.messages;
      allMessages.forEach((message) => {
        messages.push({
          id: message.id,
          requestId: message.requestId,
          type: message.type,
          timestamp: message.timestamp,
          message: message.message,
        });
      });
      resolve(messages);
    } catch {
      reject();
    }
  });
}
