import React from "react";
import WrenchIcon from "../icons/wrench";

const SuggestedRoom = ({ id, room, onRemove }) => {
  return <BoxedItem id={id} title={room} icon={RoomIcon} onRemove={onRemove} />;
};

const SuggestedArea = ({ id, area, onRemove }) => {
  return (
    <BoxedItem id={id} title={area} icon={WrenchIcon} onRemove={onRemove} />
  );
};

const SuggestedTag = ({ id, title, onRemove }) => {
  return (
    <>
      <div className="SuggestedTag">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }} />
          <div
            className="Circle"
            style={{ marginTop: -15, marginRight: -15 }}
            onClick={() => onRemove(id)}
          >
            <CloseIcon />
          </div>
        </div>
        <h1>{title}</h1>
      </div>
    </>
  );
};

const BoxedItem = ({ id, title, icon, onRemove }) => {
  return (
    <>
      <div className="SuggestedRoom">
        <div style={{ display: "flex" }}>
          {React.createElement(icon)}
          <div style={{ flex: 1 }} />
          <div
            className="Circle"
            style={{ marginTop: -15, marginRight: -15 }}
            onClick={() => onRemove(id)}
          >
            <CloseIcon />
          </div>
        </div>

        <div style={{ flex: 1 }} />
        <h1>{title}</h1>
      </div>
    </>
  );
};

const RoomIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M2 8.435l8.889 5.452v6.992L2 15.58V8.435zm19.976 0v7.144l-8.889 5.3v-6.992l8.89-5.452zM13.087 3l7.344 4.529-5.585 3.346-1.759-1.335V3zM10.89 3v6.524L9.13 10.875 3.544 7.529 10.89 3z"
        fill="#11342F"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const CloseIcon = (props) => (
  <svg width={10} height={10} viewBox="0 0 14 14" {...props}>
    <path
      d="M1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.497 1.32l-.083.094L8.415 7l5.292 5.293a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.415l-5.293 5.292a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .293 1.707A1 1 0 0 1 1.613.21z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export { SuggestedRoom, SuggestedArea, SuggestedTag };
