import React from "react";

const BackIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M11.707 5.293a1 1 0 0 1 .083 1.32l-.083.094L7.412 11H19a1 1 0 0 1 .117 1.994L19 13l-11.586-.001 4.295 4.296a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083-6.002-6.002-.073-.082a1.005 1.005 0 0 1-.007-.008l.08.09A1.007 1.007 0 0 1 4 12v-.02c0-.023.002-.046.004-.07L4 12a1.008 1.008 0 0 1 .21-.613l.007-.009c.023-.03.049-.058.076-.085l6-6a1 1 0 0 1 1.414 0z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export default BackIcon;
