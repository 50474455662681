import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function fetchAllModules(filteredParentId = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/services`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: filteredParentId,
          },
        }
      );
      const services = [];
      data.items.forEach((service) => {
        services.push({
          id: service.id,
          image: service.image,
          name: service.name,
          enabled: service.enabled,
          type: service.type,
          description: service.description,
          minVersion: service.minVersion,
          maxVersion: service.maxVersion,
        });
      });

      resolve(services);
    } catch {
      reject();
    }
  });
}

export function fetchModuleById(moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/services/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const service = data;
      resolve({
        id: service.id,
        parent: service.parent,
        image: service.image,
        name: service.name,
        enabled: service.enabled,
        type: service.type,
        description: service.description,
        minVersion: service.minVersion,
        maxVersion: service.maxVersion,
        trackingId: service.trackingId,
        uri: service.uri,
      });
    } catch (error) {
      console.log(error);
      reject();
    }
  });
}

export function updateModuleMetaData(moduleId, metadata) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/services/${moduleId}`,
        {
          name: metadata?.name,
          description: metadata?.description,
          image: metadata?.image,
          trackingId: metadata?.trackingId,
          minVersion: metadata?.minVersion,
          maxVersion: metadata?.maxVersion,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateWebviewUri(moduleId, uri) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/services/${moduleId}`,
        {
          uri,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function enableModule(moduleId, enabled) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/services/${moduleId}`,
        {
          enabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function createModule(payload, parent = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const ret = await axios.post(
        `https://api.happybuilding.app/a/services`,
        {
          name: payload?.name,
          parent: parent,
          description: payload?.description,
          type: payload?.type,
          description: payload?.description,
          image: payload?.image,
          trackingId: payload?.trackingId,
          minVersion: payload?.minVersion,
          maxVersion: payload?.maxVersion,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(ret.data?.id);
    } catch {
      reject();
    }
  });
}

export function deleteModuleById(moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/services/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}
