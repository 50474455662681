import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function fetchMaintenanceSuggestions() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/concierge/settings/maintenance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve({
        rooms: data?.rooms,
        areas: data?.areas,
        concernTypes: data?.concernTypes,
        concernTags: data?.concernTags,
      });
    } catch {
      reject();
    }
  });
}

export function addMaintenanceRoom(label) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.post(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/room`,
        {
          label,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(data?.id);
    } catch {
      reject();
    }
  });
}

export function addMaintenanceArea(area) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.post(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/area`,
        {
          label: area,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(data?.id);
    } catch {
      reject();
    }
  });
}

export function addMaintenanceConcernTypeByArea(concern, area) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.post(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/concern`,
        {
          label: concern,
          area: area,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(data?.id);
    } catch {
      reject();
    }
  });
}

export function addMaintenanceConcernTagByType(tag, concern) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.post(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/tag`,
        {
          label: tag,
          concern: concern,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(data?.id);
    } catch {
      reject();
    }
  });
}

export function deleteMaintenanceRoom(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/room/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteMaintenanceArea(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/area/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteMaintenanceConcernType(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/concern/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteMaintenanceConcernTag(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/concierge/settings/maintenance/tag/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function fetchConciergeNotifications() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/concierge/settings/notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const notifications = [];
      data.items.forEach((notification) => {
        notifications.push({
          id: notification.id,
          title: notification.title,
          subtitle: notification.subtitle,
          value: notification.value,
        });
      });

      resolve(notifications);
    } catch {
      reject();
    }
  });
}

export function updateConciergeNotificationSetting(id, toggled) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/concierge/settings/notifications/${id}`,
        {
          value: toggled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      resolve();
    } catch {
      reject();
    }
  });
}
