import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Badge, ListGroup, ListGroupItem } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { updateNewsItemPositionByIndex } from "../action";
import { fetchNewsItems } from "../action/news";
import ModuleHeader from "../../../components/ModuleHeader";
import LoadingScreen from "../../../screens/LoadingScreen";
import ReactGA from "react-ga";

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

const DragHandle = SortableHandle(() => (
  <img
    alt=""
    src={require("./../../../img/sort.png")}
    style={{ height: 20, width: 30, marginRight: 20 }}
  />
));

const SortableNewsItem = SortableElement(({ item, history, site }) => {
  return (
    <ListGroupItem
      action
      onClick={() => {
        history.push("/modules/building/news/create", {
          item: item,
          site: site,
        });
      }}
      style={{ cursor: "pointer" }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <DragHandle />
        <div
          className="ActiveCollapseItem"
          style={{ flex: 1, justifyContent: "center" }}
        >
          <h2 style={{ padding: 0, margin: 0 }}>{item.title}</h2>
        </div>
        <div style={{ alignContent: "center", justifyContent: "center" }}>
          {item.published_mobile ? <Badge>mobile</Badge> : null}
        </div>
      </div>
    </ListGroupItem>
  );
});

const SortableNewsList = SortableContainer(({ items, history, site }) => {
  return (
    <ListGroup>
      <ListGroupItem
        disabled
        style={{ backgroundColor: "#e8ecef", paddingTop: 0, paddingBottom: 0 }}
      >
        <div
          className="ListHeader"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ width: 50 }} />
          <div style={{ flex: 1 }}>item</div>
          <div style={{}}>published</div>
        </div>
      </ListGroupItem>
      {items.length === 0 ? (
        <div className="ListEmpty">There are no entries here</div>
      ) : (
        items.map((value, index) => (
          <SortableNewsItem
            key={`item-${index}`}
            index={index}
            item={value}
            history={history}
            site={site}
          />
        ))
      )}
    </ListGroup>
  );
});

class NewsInformationScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedNewsItems: [],
      loading: false,
    };
  }

  componentDidMount() {
    ReactGA.pageview(`/modules/building/news//`);

    this.setState({ loading: true });
    fetchNewsItems()
      .then((items) => {
        this.setState({ sortedNewsItems: items, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div style={{ paddingBlockEnd: 50 }}>
        <ModuleHeader title="Building" subTitle="News & Events" />
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div style={{ flex: 1, display: "flex", alignItems: "center" }} />
              <Button color="secondary" onClick={this.onCreate} size="sm">
                Create Item
              </Button>
            </div>
            <SortableNewsList
              items={this.state.sortedNewsItems}
              history={this.props.history}
              useDragHandle={true}
              onSortEnd={this.onNewsSortEnd}
              site={this.props.configuration.data.id}
            />
          </>
        )}
      </div>
    );
  }

  onCreate = () => {
    if (this.props.history) {
      this.props.history.push("/modules/building/news/create", { item: null });
    }
  };

  onNewsSortEnd = ({ oldIndex, newIndex }) => {
    let newSortedItems = arrayMove(
      this.state.sortedNewsItems,
      oldIndex,
      newIndex
    );

    this.setState({
      sortedNewsItems: newSortedItems,
    });

    // update the backend with new sort order
    let posObject = {};
    newSortedItems.map((newsItem, index) => {
      posObject[newsItem.id] = { position: index };
      return true;
    });
    updateNewsItemPositionByIndex(this.props.configuration.data.id, posObject);
  };
}

export default withRouter(connect(mapStateToProps)(NewsInformationScreen));
