import React from "react";

const Logo = (props) => (
  <svg width={610} height={807} viewBox="0 0 610 807" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width={610} height={807} rx={83} fill="#FFF" fillRule="nonzero" />
      <path
        d="M138 560c48.247 48.034 107.054 72.052 176.422 72.052S442.597 608.034 490.843 560"
        stroke="#464861"
        strokeWidth={49}
      />
    </g>
  </svg>
);

export default Logo;
