import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import '../App.css';

const mapStateToProps = function(state){
  return {
  }
}

class NotFoundScreen extends Component {
  componentDidMount() {
    ReactGA.pageview('/404');
  }
  render() {
    return (
      <div>
      404 Route not found
      </div>
    );
  }
}

export default connect(mapStateToProps)(NotFoundScreen)
