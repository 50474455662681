import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";
import ReactGA from "react-ga";

import ModuleHeader from "../../../components/ModuleHeader";
import { fetchAllUsers, updateUser } from "../actions/residents";
import LoadingScreen from "../../../screens/LoadingScreen";
import { BrowserView, isBrowser, isMobile, MobileView } from "react-device-detect";
import { inviteResident } from "../actions/residents";
import NotificationManager from "react-notifications/lib/NotificationManager";

const EditResidentModal = ({
  isOpen,
  onToggle,
  residentId,
  email,
  room,
  comments,
  onUpdate,
  onInvite,
  create,
  pending,
}) => {
  const [invitationEmail, setInvitationEmail] = useState();
  const [residentRoom, setResidentRoom] = useState();
  const [residentComments, setResidentComments] = useState();

  useEffect(() => {
    setResidentRoom(room ?? "");
  }, [room]);
  useEffect(() => {
    setResidentComments(comments ?? "");
  }, [comments]);

  const onToggleEvent = () => {
    onToggle();
    clear();
  };

  const clear = () => {
    setResidentComments("");
    setResidentRoom("");
    setInvitationEmail("");
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggleEvent}>
      <Form
        onSubmit={(event) => {
          // update user permissions
          if (create) {
            onInvite(invitationEmail);
          } else {
            onUpdate(residentId, {
              room: residentRoom,
              comments: residentComments,
            });
          }
          event.preventDefault();
        }}
      >
        <ModalHeader>{create ? "Invite Resident" : email}</ModalHeader>
        <ModalBody>
          <div>
            {create ? (
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={invitationEmail}
                  onChange={(event) => setInvitationEmail(event.target.value)}
                />
              </FormGroup>
            ) : null}
            {pending || create ? (
              pending ? (
                <div>Awaiting response from resident</div>
              ) : null
            ) : (
              <>
                <FormGroup>
                  <Label>Room / Address</Label>
                  <Input type="text" value={residentRoom} onChange={(event) => setResidentRoom(event.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Comments</Label>
                  <Input
                    type="text"
                    value={residentComments}
                    onChange={(event) => setResidentComments(event.target.value)}
                  />
                </FormGroup>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Button color="secondary" onClick={onToggle} style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button color="danger" onClick={onToggle} style={{ marginRight: 10 }}>
              Remove Resident
            </Button>
            <div style={{ flex: 1 }} />
            <Button color="success" type="submit">
              {create ? "Invite" : "Save"}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

class UserManagementScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      residents: [],
      searchFilter: null,
      isModalOpen: false,
      selectedResident: null,
    };
  }
  componentDidMount() {
    ReactGA.pageview("/modules/app/users/");

    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configuration.data.id !== this.props.configuration.data.id) {
      this.refresh();
    }
  }

  refresh() {
    this.setState({ loading: true });

    fetchAllUsers()
      .then((residents) => {
        this.setState({ residents: residents, loading: false });
      })
      .catch(() => {
        this.setState({ residents: [], loading: false });
      });
  }

  onSearch = (text) => {
    this.setState({ searchFilter: text });
  };

  render() {
    return (
      <Fragment>
        <ModuleHeader
          title="App Management"
          subTitle="Residents"
          action
          actionTitle="Invite Resident"
          onAction={this.onCreateUser}
          search
          onSearchChange={this.onSearch}
        />
        {this.renderResidents()}
        <EditResidentModal
          residentId={this.state.selectedResident?.id}
          create={!this.state.selectedResident}
          email={this.state.selectedResident?.email}
          room={this.state.selectedResident?.room}
          comments={this.state.selectedResident?.comments}
          pending={this.state.selectedResident?.pending}
          isOpen={this.state.isModalOpen}
          onToggle={() => this.setState({ isModalOpen: !this.state.isModalOpen })}
          onUpdate={(residentId, { room, comments }) => {
            updateUser(
              residentId, {
                room,
                comments,
              }
            ).then(() => {
                NotificationManager.success("Resident Updated");
                this.refresh();
                this.setState({ isModalOpen: false }, () => {
                  this.setState({ selectedResident: null }); // timing so we don't re-render until it's clsoed
                });
              })
              .catch(() => {
                NotificationManager.error("Resident Update Failed");
              });
          }}
          onInvite={(email) => {
            inviteResident(this.props.configuration.data.id, this.props.configuration.data.domain, email)
              .then(() => {
                NotificationManager.success("Resident Invited");
                this.setState({ isModalOpen: false }, () => {
                  this.setState({ selectedResident: null }); // timing so we don't re-render until it's clsoed
                });
              })
              .catch((rror) => {
                NotificationManager.error("Resident Invitation Failed");
              });
          }}
        />
      </Fragment>
    );
  }

  renderResidents = () => {
    if (this.state.loading) {
      return <LoadingScreen />;
    } else {
      return (
        <Fragment>
          <ListGroup>
            <ListGroupItem
              disabled
              style={{
                backgroundColor: "#e8ecef",
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <MobileView>
                <div className="ListHeader" style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ flex: 1 }}>resident</div>
                </div>
              </MobileView>
              <BrowserView>
                <div className="ListHeader" style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>email</div>
                </div>
              </BrowserView>
            </ListGroupItem>
            {this.state.residents.length > 0 ? (
              this.state.residents.map((resident) => {
                // filter out based on search filter (email / firstname / lastname)
                const searchString = resident.email;
                if (
                  this.state.searchFilter &&
                  !searchString.toLowerCase().includes(this.state.searchFilter.toLowerCase())
                ) {
                  return null;
                }

                return (
                  <ListGroupItem
                    key={resident.email}
                    action
                    style={{ cursor: "pointer" }}
                    onClick={this.onModifyUser(resident)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                      }}
                    >
                      <div style={{ flex: 1 }}>{resident.email}</div>
                      {resident.pending ? (
                        <div>
                          <Badge>pending</Badge>
                        </div>
                      ) : null}
                    </div>
                  </ListGroupItem>
                );
              })
            ) : (
              <div className="EmptyListResponse" style={{ marginTop: 20, textAlign: "center" }}>
                There are no residents
              </div>
            )}
          </ListGroup>
        </Fragment>
      );
    }
  };

  onModifyUser = (user) => () => {
    this.setState({ selectedResident: user, isModalOpen: true });
  };

  onCreateUser = () => {
    this.setState({ selectedResident: null, isModalOpen: true });
  };
}

export default withRouter(connect(mapStateToProps)(UserManagementScreen));
