import React from "react";

const ProfileIcon = (props) => (
  <svg width={20} height={20} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
        fill="#464861"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ProfileIcon;
