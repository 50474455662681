import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function getNewsItemImages() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/building/images`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve({
        templates: data?.templates,
        uploads: data?.uploads,
      });
    } catch {
      reject();
    }
  });
}

export function addUploadedImage(uri) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/a/building/images`,
        {
          uri,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function removeUploadedImage(imageKey) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/building/images/${imageKey}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}
