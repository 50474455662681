import React, { useEffect, useState, useCallback } from "react";
import { NotificationManager } from "react-notifications";
import { createModule } from "../actions/modules";
import AppComponent from "./components/AppModule";
import LoadingScreen from "../../../screens/LoadingScreen";
import { fetchAllModules, enableModule } from "../actions/modules";
import { Button, Label } from "reactstrap";
import { CreateModuleModal } from "./components/CreateModuleModal";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const SublanderServiceSettingsPriv = ({ history, site, domain, moduleId }) => {
  const [createModal, setCreateModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const refresh = useCallback(async () => {
    fetchAllModules(moduleId)
      .then((subModules) => {
        setModules(subModules);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setModules([]);
        setError(true);
        setLoading(false);
      });
  }, [moduleId, site]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const createModuleByType = (type, minVersion) => async () => {
    try {
      const newModuleId = await createModule(
        {
          type,
          minVersion,
        },
        moduleId
      );
      onModuleCreated(newModuleId);
    } catch {
      NotificationManager.error("Unable to create your module");
    }
  };

  const onModuleCreated = (subLanderModuleId) => {
    refresh();
    setCreateModal(false);

    history.push(
      `/modules/app/services/settings/${moduleId}/${subLanderModuleId}`,
      {
        site: site,
        firstLaunch: true,
      }
    );
  };

  const onEditPressed = (subLanderModuleId) => () => {
    history.push(
      `/modules/app/services/settings/${moduleId}/${subLanderModuleId}`,
      {
        site: site,
        firstLaunch: false,
      }
    );
  };

  const onModuleToggled = (id) => (enabled) => {
    enableModule(id, enabled)
      .then(() => {
        NotificationManager.success("Service Updated");
        refresh();

        ReactGA.event({
          category: "App Management",
          action: "Module Toggled",
          label: "Enabled",
          value: Number(enabled),
        });
      })
      .catch(() => {
        NotificationManager.error("Service Failed to update");
        refresh();
      });
  };

  return (
    <>
      <div className="ModuleSettingsContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h1 style={{ flex: 1 }}>Services</h1>
          <Button onClick={() => setCreateModal(true)}>Create Service</Button>
        </div>
        <Label>List of services that belong to this Lander</Label>
        {!loading ? (
          modules.map((module) => (
            <div key={module.id} style={{ minWidth: 500, marginBottom: 10 }}>
              <AppComponent
                color={module.color}
                icon={module.image}
                name={module.name}
                description={module.description}
                type={module.type}
                enabled={module.enabled}
                minVersion={module.minVersion}
                maxVersion={module.maxVersion}
                onModuleToggled={onModuleToggled(module.id)}
                editable={true}
                onEditPressed={onEditPressed(module.id)}
              />
            </div>
          ))
        ) : (
          <LoadingScreen />
        )}
      </div>
      <CreateModuleModal
        isOpen={createModal}
        toggle={() => setCreateModal(false)}
        onModuleTypeSelected={createModuleByType}
      />
    </>
  );
};

const SublanderServiceSettings = withRouter(SublanderServiceSettingsPriv);

export { SublanderServiceSettings };
