import React, { useEffect, useState } from "react";
import LoadingScreen from "../../../screens/LoadingScreen";
import { fetchOtherRequestMessages, fetchRequestMessages } from "../action";
import moment from "moment";

const MessageItem = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#e6e6e6",
        backgroundColor: "#e6e6e6",
        padding: 10,
      }}
    >
      <span style={{ fontSize: "0.75em" }}>
        {moment(message.timestamp).format("DD/MM/YY hh:mm a")}
      </span>
      {message.message}
    </div>
  );
};

const RequestMessageList = ({ requestId, lastRefresh }) => {
  const [ready, setReady] = useState(false);
  const [messages, setMessages] = useState([]);
  const fetchMessages = async () => {
    try {
      setReady(false);
      const messageResponse = await fetchOtherRequestMessages(
        requestId
      );
      setMessages(messageResponse.sort(_sortTimestamp));
      setReady(true);
    } catch {
      setReady(true);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [lastRefresh]);

  return <MessageList messages={messages} ready={ready} />;
};

const MaintenanceMessageList = ({ requestId, lastRefresh }) => {
  const [ready, setReady] = useState(false);
  const [messages, setMessages] = useState([]);
  const fetchMessages = async () => {
    try {
      setReady(false);
      const messageResponse = await fetchRequestMessages(requestId);
      setMessages(messageResponse.sort(_sortTimestamp));
      setReady(true);
    } catch {
      setReady(true);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [lastRefresh]);

  return <MessageList messages={messages} ready={ready} />;
};

const MessageList = ({ messages, ready }) => {
  if (!ready) {
    return <LoadingScreen />;
  }

  return (
    <>
      {messages?.map((message) => {
        return (
          <div key={message.id} style={{ marginTop: 20 }}>
            <MessageItem message={message} />
          </div>
        );
      })}
    </>
  );
};

function _sortTimestamp(a, b) {
  // primary sort on position
  if (a.timestamp > b.timestamp) {
    return -1;
  } else if (a.timestamp < b.timestamp) {
    return 1;
  }
  return 0;
}

export { MaintenanceMessageList, RequestMessageList };
