import React, { useEffect} from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Welcome = () => {

  const authentication = useSelector(state => state.authentication);
  
  useEffect( () => {
    ReactGA.pageview('/welcome');
  }, [] );

  return (
    <div className="welcome"><h1>{`Good ${getTimeOfDay()}, ${authentication.user.name}`}</h1></div>
  );
}

function getTimeOfDay() {
  const currentHour = moment().format("HH");
  if (currentHour >= 5 && currentHour <= 12) {
    return 'Morning';
  } else if (currentHour > 12 && currentHour < 17) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
}

export default Welcome;