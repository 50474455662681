import React, { useCallback, useEffect, useState } from "react";
import ModuleHeader from "../../../components/ModuleHeader";
import { withRouter } from "react-router-dom";
import {
  SuggestedRoom,
  SuggestedArea,
  SuggestedTag,
} from "./components/SuggestedItems";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import AutosizeInput from "react-input-autosize";
import {
  addMaintenanceArea,
  addMaintenanceConcernTagByType,
  addMaintenanceConcernTypeByArea,
  addMaintenanceRoom,
  deleteMaintenanceArea,
  deleteMaintenanceConcernTag,
  deleteMaintenanceConcernType,
  deleteMaintenanceRoom,
  fetchMaintenanceSuggestions,
  fetchConciergeNotifications,
  updateConciergeNotificationSetting,
} from "../action/settings";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import Toggle from "react-toggle";
import LoadingScreen from "../../../screens/LoadingScreen";

const SettingsScreen = () => {
  const configuration = useSelector((state) => state.configuration);

  const [rooms, setRooms] = useState([]);
  const [areas, setAreas] = useState([]);
  const [concerns, setConcerns] = useState({});
  const [concernTags, setConcernTags] = useState({});
  const [selectedArea, setSelectedArea] = useState();
  const [selectedConcern, setSelectedConcern] = useState();

  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const refreshNotifications = useCallback(async () => {
    try {
      setLoadingNotifications(true);
      const notifs = await fetchConciergeNotifications();
      setNotifications(notifs);
    } catch {
      setNotifications([]);
    }
    setLoadingNotifications(false);
  }, [configuration.data.id]);

  useEffect(() => {
    refreshNotifications();
  }, [refreshNotifications]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await fetchMaintenanceSuggestions();
        setRooms(response.rooms);
        setAreas(response.areas);

        // iterate through types by area and sort
        let cTypes = {};
        response.concernTypes.forEach((concernType) => {
          if (!cTypes[concernType.parent]) {
            cTypes[concernType.parent] = [];
          }
          cTypes[concernType.parent].push({
            id: concernType.id,
            label: concernType.label,
          });
        });
        setConcerns(cTypes);

        // iterate through tags by type and sort
        let cTags = {};
        response.concernTags.forEach((concernTag) => {
          if (!cTags[concernTag.parent]) {
            cTags[concernTag.parent] = [];
          }
          cTags[concernTag.parent].push({
            id: concernTag.id,
            label: concernTag.label,
          });
        });
        setConcernTags(cTags);
      } catch {
        console.log("Bad things happened");
      }
    };
    fetch();
  }, []);

  return (
    <>
      <ModuleHeader title="Concierge Services" subTitle="Settings" />
      <div className="SettingsBody">
        <h1>Maintenance </h1>
        <div className="SettingsContainer">
          <div
            className="SettingsItem"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1>Rooms</h1>
            <h2>
              The list of suggested rooms the maintenance request is being made
              for
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <AddEntryInput
                entryTitle="Add Room"
                placeholder="New Room"
                onAddEntry={(entry) => {
                  addMaintenanceRoom(entry)
                    .then((newId) => {
                      const newEntry = {
                        label: entry,
                        id: newId,
                      };
                      setRooms((state) => state.concat([newEntry]));
                    })
                    .catch(() => {
                      NotificationManager.error("Unable to add Room");
                    });
                }}
              />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
              {rooms.map((room) => (
                <SuggestedRoom
                  key={room.id}
                  id={room.id}
                  room={room.label}
                  onRemove={(id) => {
                    deleteMaintenanceRoom(id)
                      .then(() => {
                        let newRooms = rooms.filter((value) => value.id !== id);
                        setRooms(newRooms);
                      })
                      .catch(() => {
                        NotificationManager.error("Unable to remove Room");
                      });
                  }}
                />
              ))}
            </div>
          </div>
          <div
            className="SettingsItem"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1>Area of Concern</h1>
            <h2>
              The list of suggested Areas of Concern the request is for
              (Electrical, Plumbing, etc)
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <AddEntryInput
                entryTitle="Add Area"
                placeholder="New Area"
                onAddEntry={(entry) => {
                  addMaintenanceArea(entry)
                    .then((newId) => {
                      const newEntry = {
                        label: entry,
                        id: newId,
                      };
                      setAreas((state) => state.concat([newEntry]));
                    })
                    .catch(() => {
                      NotificationManager.error("Unable to add Area");
                    });
                }}
              />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
              {areas.map((area) => (
                <SuggestedArea
                  key={area.id}
                  id={area.id}
                  area={area.label}
                  onRemove={(id) => {
                    deleteMaintenanceArea(id)
                      .then(() => {
                        setAreas(areas.filter((value) => value.id !== id));
                      })
                      .catch(() => {
                        NotificationManager.error("Unable to remove Area");
                      });
                  }}
                />
              ))}
            </div>
          </div>
          <div
            className="SettingsItem"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1>Suggested Concern Types</h1>
            <h2>
              The suggested list of Types of Concerns that may occur, specific
              to each area of concern
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <FilteredSelected
                disabled={areas.length === 0}
                items={areas}
                label="Selected Area of Concern"
                onSelected={(id) => {
                  setSelectedArea(id);
                }}
              />
              <AddEntryInput
                disabled={selectedArea == null}
                entryTitle="Add Concern Type"
                placeholder="New Type"
                onAddEntry={(entry) => {
                  addMaintenanceConcernTypeByArea(
                    entry,
                    selectedArea
                  )
                    .then((newId) => {
                      const newEntry = {
                        label: entry,
                        id: newId,
                      };
                      setConcerns((state) => {
                        const newState = { ...state };
                        if (!newState[selectedArea]) {
                          newState[selectedArea] = [];
                        }
                        newState[selectedArea].push(newEntry);
                        return newState;
                      });
                    })
                    .catch(() => {
                      NotificationManager.error("Unable to add Concern Type");
                    });
                }}
              />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
              {concerns[selectedArea]?.map((concern) => (
                <SuggestedTag
                  key={concern.id}
                  id={concern.id}
                  title={concern.label}
                  onRemove={(id) => {
                    deleteMaintenanceConcernType(id)
                      .then(() => {
                        setConcerns((state) => {
                          const newState = { ...state };
                          newState[selectedArea] = newState[
                            selectedArea
                          ].filter((value) => value.id !== id);
                          return newState;
                        });
                      })
                      .catch(() => {
                        NotificationManager.error("Unable to remove Type");
                      });
                  }}
                />
              ))}
            </div>
            <h1>Suggested Concern Type Tags</h1>
            <h2>Concern Type Tags specific to each concern type</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <FilteredSelected
                disabled={concerns[selectedArea]?.length === 0}
                items={concerns[selectedArea] ?? []}
                label="Selected Concern Type"
                onSelected={(id) => {
                  setSelectedConcern(id);
                }}
              />
              <AddEntryInput
                disabled={selectedConcern == null}
                entryTitle="Add Concern Type Tag"
                placeholder="New Tag"
                onAddEntry={(entry) => {
                  addMaintenanceConcernTagByType(
                    entry,
                    selectedConcern
                  )
                    .then((newId) => {
                      const newEntry = {
                        label: entry,
                        id: newId,
                      };
                      setConcernTags((state) => {
                        const newState = { ...state };
                        if (!newState[selectedConcern]) {
                          newState[selectedConcern] = [];
                        }
                        newState[selectedConcern].push(newEntry);
                        return newState;
                      });
                    })
                    .catch(() => {
                      NotificationManager.error("Unable to add Concern Tag");
                    });
                }}
              />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
              {concernTags[selectedConcern]?.map((concernTag) => (
                <SuggestedTag
                  key={concernTag.id}
                  id={concernTag.id}
                  title={concernTag.label}
                  onRemove={(id) => {
                    deleteMaintenanceConcernTag(id)
                      .then(() => {
                        setConcernTags((state) => {
                          const newState = { ...state };
                          newState[selectedConcern] = newState[
                            selectedConcern
                          ].filter((value) => value.id !== id);
                          return newState;
                        });
                      })
                      .catch(() => {
                        NotificationManager.error("Unable to remove Type");
                      });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <h1>Notifications</h1>
        <div className="SettingsContainer">
          {loadingNotifications ? <LoadingScreen /> : null}
          {notifications.map((notification) => (
            <ToggleLineItem
              key={notification.id}
              title={notification.title}
              subtitle={notification.subtitle}
              toggleValue={notification.value}
              onToggled={(toggled) => {
                updateConciergeNotificationSetting(
                  notification.id,
                  toggled
                )
                  .then(() => {
                    NotificationManager.success("Notification Setting Updated");
                  })
                  .catch(() => {
                    NotificationManager.error(
                      "Unable to update Notification Setting"
                    );
                  });
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ToggleLineItem = ({ title, subtitle, toggleValue, onToggled }) => {
  return (
    <div
      className="SettingsItem"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <div style={{ display: "flex", alignContent: "center" }}>
          <Toggle
            icons={false}
            defaultChecked={toggleValue}
            onChange={(event) => {
              onToggled(event.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const FilteredSelected = ({ label, items, onSelected, disabled }) => {
  const [selected, setSelected] = useState(label);
  const [open, setOpen] = useState(false);

  return (
    <ButtonDropdown
      disabled={disabled}
      style={{ marginRight: 10 }}
      isOpen={open}
      toggle={() => setOpen((state) => !state)}
    >
      <DropdownToggle caret>{selected}</DropdownToggle>
      <DropdownMenu>
        {items.map((item) => (
          <DropdownItem
            key={item.id}
            onClick={() => {
              setSelected(item.label);
              onSelected(item.id);
            }}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const AddEntryInput = ({ entryTitle, placeholder, onAddEntry, disabled }) => {
  const [entry, setEntry] = useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <AutosizeInput
        disabled={disabled}
        placeholder={placeholder}
        value={entry}
        onChange={(event) => setEntry(event.target.value)}
        inputStyle={{
          minWidth: 120,
          padding: 5,
          display: "flex",
          justifyContent: "center",
          borderRadius: 10,
          outline: "none",
          border: "solid 1px rgba(0, 0, 0, 0.4)",
        }}
        style={{
          marginRight: 10,
        }}
      />
      <Button
        disabled={entry.length === 0}
        onClick={() => {
          onAddEntry(entry);
          setEntry("");
        }}
      >
        {entryTitle}
      </Button>
    </div>
  );
};

export default withRouter(SettingsScreen);
