import React, { Component } from 'react';

import { ClipLoader } from 'react-spinners';

class LoadingScreen extends Component {

  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
          <ClipLoader loading={true}/>
        </div>
    );
  }
}

export default LoadingScreen;
