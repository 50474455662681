import React from "react";

const RoomIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M2 8.435l8.889 5.452v6.992L2 15.58V8.435zm19.976 0v7.144l-8.889 5.3v-6.992l8.89-5.452zM13.087 3l7.344 4.529-5.585 3.346-1.759-1.335V3zM10.89 3v6.524L9.13 10.875 3.544 7.529 10.89 3z"
        fill="#11342F"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default RoomIcon;
