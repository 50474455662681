import React, { useState, useEffect } from "react";

import { Nav, NavLink } from "reactstrap";

import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";

import "./../App.css";
import { ModuleMapUserInfo } from "../screens/user/config";
import BackIcon from "./icons/BackIcon";
import { useRecoilState } from "recoil";
import { NavigationBarIsOpen } from "../SharedState";
import { Transition } from "react-transition-group";
import { isMobile } from "react-device-detect";

const SideBarWrapper = (props) => {
  if (isMobile) {
    return (
      <Transition in={props.isOpen} timeout={0}>
        {(state) => <div className={`fade-${state}`}>{props.children}</div>}
      </Transition>
    );
  }
  return props.children;
};

const LoggedInUserInfoSideBarView = () => {
  const [modules, setModules] = useState([]);
  const [isNavBarOpen, setIsNavBarOpen] = useRecoilState(NavigationBarIsOpen);

  useEffect(() => {
    let modules = [];
    for (var module in ModuleMapUserInfo) {
      modules.push(ModuleMapUserInfo[module].sidebar);
    }
    setModules(modules);
  }, []);

  return (
    <SideBarWrapper isOpen={isNavBarOpen}>
      <Nav navbar className="SideBar">
        <div className="SideBarButton" onClick={() => window.open("/", "_self")}>
          <BackIcon />
          <span style={{ padding: 0, margin: 0 }}>Back to Admin Portal</span>
        </div>
        {modules.map((module) => {
          return (
            <div key={module.title} className="SideBarModule">
              <div
                className="SideBarHeader"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {module.icon ? (
                  React.createElement(module.icon, {
                    style: { marginLeft: 10, marginRight: 10 },
                  })
                ) : (
                  <span style={{ width: 20 }} />
                )}
                <h1>{module.title}</h1>
              </div>
              <div className="SideBarModuleLinks">
                {module.items.map((item) => {
                  return (
                    <LineItem key={item.path} show={true}>
                      <LinkContainer
                        to={item.path}
                        style={{ color: "#FFFFFF", padding: 0, paddingLeft: 5 }}
                        activeStyle={{
                          backgroundColor: "rgba(256, 256, 256, 0.14)",
                        }}
                      >
                        <NavLink
                          onClick={() => {
                            setTimeout(() => setIsNavBarOpen(false), 500);
                          }}
                        >
                          {item.title}
                        </NavLink>
                      </LinkContainer>
                    </LineItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Nav>
    </SideBarWrapper>
  );
};

function LineItem(props) {
  if (props.show === true) {
    return props.children;
  } else {
    return null;
  }
}

export default withRouter(LoggedInUserInfoSideBarView);
