import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { useSetRecoilState } from "recoil";
import { NavigationBarIsOpen, ProfileBoxIsOpen } from "../SharedState";
import Gravatar from "react-gravatar";
import HamburgerIcon from "./icons/HamburgerIcon";
import Logo from "./icons/Logo";

const BuildingDropdown = () => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);

  if (authentication.loggedIn) {
    return (
      <>
        {configuration.currentState === "SUCCESS" ? (
          <span className="SiteHeader">
            {configuration.data.sitenames[configuration.data.id]}
          </span>
        ) : (
          <span className="SiteHeader">&nbsp;</span>
        )}
      </>
    );
  } else {
    return <span className="SiteHeader">&nbsp;</span>;
  }
};

const LoggedInInformation = () => {
  const authentication = useSelector((state) => state.authentication);
  const setProfileBoxIsOpen = useSetRecoilState(ProfileBoxIsOpen);

  if (authentication.loggedIn) {
    return (
      <div className="LoggedInHeader">
        <div
          style={{
            alignContent: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <BrowserView>
            <div style={{ marginRight: 10 }}>
              <BuildingDropdown />
            </div>
          </BrowserView>
          <div>
            <div className="ProfileBox-Gravatar">
              <Gravatar
                style={{ borderRadius: 5 }}
                email={authentication.user.email}
                size={36}
                rating="pg"
                default="monsterid"
                className="CustomAvatar-image"
                onClick={() => setProfileBoxIsOpen((state) => !state)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!authentication.initializing && !authentication.processing) {
    return (
      <div className="LoggedInHeader">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/login">
              <Button style={{ padding: 5 }}>
                <h2>Login</h2>
              </Button>
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const NavHeader = ({ onSiteChange }) => {
  const authentication = useSelector((state) => state.authentication);
  const setIsNavBarOpen = useSetRecoilState(NavigationBarIsOpen);

  return (
    <div
      className="AppHeader"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyItems: "center",
      }}
    >
      <MobileView>
        <HamburgerIcon
          style={{ marginRight: 10 }}
          onClick={() => setIsNavBarOpen((state) => !state)}
        />
      </MobileView>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Logo style={{ height: 50, width: 38, marginRight: 10 }} />
          <h1
            style={{ margin: 0, padding: 0, alignSelf: "flex-end" }}
            onClick={() => window.open("/", "_self")}
          >
            Happy Building
          </h1>
        </div>

        <MobileView>
          <BuildingDropdown />
        </MobileView>
      </div>
      <div style={{ flex: 5, display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }} />
        {authentication.loggedIn ? (
          <LoggedInInformation onSiteChange={onSiteChange} />
        ) : null}
      </div>
    </div>
  );
};

export default NavHeader;
