import React from "react";

const CloseLightIcon = (props) => (
  <svg width={14} height={14} {...props}>
    <path
      d="M1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.497 1.32l-.083.094L8.415 7l5.292 5.293a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.415l-5.293 5.292a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .293 1.707A1 1 0 0 1 1.613.21z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export default CloseLightIcon;
