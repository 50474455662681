import React from "react";

const AppManagementIcon = (props) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 10 }} fill="currentColor">
    <path
      fillRule="evenodd"
      d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
      clipRule="evenodd"
    />
  </svg>
);

export default AppManagementIcon;
