import React from "react";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

export default class BadgeDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      value: props.defaultValue,
    };
  }

  render() {
    const { items, disabled } = this.props;
    return (
      <ButtonDropdown
        direction="down"
        size="med"
        isOpen={this.state.open}
        toggle={this.onDropDownStateChange}
      >
        <DropdownToggle disabled={disabled} caret style={{ fontSize: "1em" }}>
          <span>{this.state.value}</span>
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item) => {
            const id = item.id;
            const title = item.name; // TODO get this value
            return (
              <DropdownItem
                key={id}
                onClick={this.onDropDownItemSelected(id, title)}
              >
                {title}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }

  onDropDownStateChange = () => {
    this.setState({ open: !this.state.open });
  };

  onDropDownItemSelected = (id, item) => () => {
    this.setState({ value: item });

    if (this.props.onDropDownChange) {
      this.props.onDropDownChange(id);
    }
  };
}
