import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import ModuleHeader from "../../../components/ModuleHeader";
import Gravatar from "react-gravatar";
import BadgeDropdown from "./components/BadgeDropdown";
import { NotificationManager } from "react-notifications";
import { fetchOtherRequestById, updateOtherRequestStateById } from "../action";
import LoadingScreen from "../../../screens/LoadingScreen";
import { RequestMessageList } from "./MessageList";
import moment from "moment";

const STATE_TYPES = [
  {
    id: "open",
    name: "Open",
  },
  {
    id: "ongoing",
    name: "In-Progress",
  },
  {
    id: "resolved",
    name: "Resolved",
  },
];

const OtherRequestDetails = ({ requestId, onUpdate }) => {
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    // fetch the request by ID
    const fetch = async () => {
      try {
        const request = await fetchOtherRequestById(requestId);
        setRequest(request);
        setLoading(false);
      } catch {
        setError(true);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!loading) {
      setUpdating(true);
      updateOtherRequestStateById(requestId, request.state)
        .then(() => {
          if (onUpdate) {
            onUpdate();
          }
          NotificationManager.success(`Request changed to ${request.state}`);
          setUpdating(false);
        })
        .catch(() => {
          NotificationManager.error("Unable to update state");
          setUpdating(false);
        });
    }
  }, [request.state]);

  const onRequestStateChange = (state) => {
    setRequest({ ...request, state });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return null;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 10,
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#c6c6c6",
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Gravatar
            style={{ borderRadius: 5 }}
            email={request.user.email}
            size={24}
            rating="pg"
            default="monsterid"
            className="CustomAvatar-image"
          />
          <span
            style={{
              fontSize: "1.25em",
              fontWeight: "200",
              marginLeft: 5,
              flex: 1,
            }}
          >
            {request.user.name} - Room {request.user.room}
          </span>
          <BadgeDropdown
            defaultValue={request.state}
            items={STATE_TYPES}
            onDropDownChange={onRequestStateChange}
            disabled={updating}
          />
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: "#f6f6f6",
            marginTop: 5,
            marginBottom: 5,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            backgroundColor: "#f8f8f8",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <span
            style={{
              fontSize: "1em",
              fontWeight: "400",
              marginLeft: 5,
            }}
          >
            {request.details}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: 10,
            backgroundColor: "#f8f8f8",
            borderRadius: 10,
            padding: 5,
          }}
        >
          {request.images?.map((image) => (
            <div key={image} style={{ padding: 10 }}>
              <img
                src={image}
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const OtherRequestItem = ({ location, history }) => {
  const [lastRefresh, setLastRefresh] = useState(moment().valueOf());

  const goBack = () => {
    history.push("/modules/concierge/other");
  };

  const requestId = location.pathname.split("/")[4];

  const onUpdate = () => {
    setLastRefresh(moment().valueOf());
  };

  return (
    <>
      <ModuleHeader title="Concierge Services" subTitle={`Other Requests`} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton title="Back to Other Requests" onClick={goBack} />
        <div style={{ flex: 1 }} />
      </div>

      <OtherRequestDetails
        requestId={requestId}
        onUpdate={onUpdate}
      />
      <div style={{ marginTop: 30 }}>
        <RequestMessageList
          requestId={requestId}
          lastRefresh={lastRefresh}
        />
      </div>
    </>
  );
};

export default withRouter(OtherRequestItem);
