import firebase from "firebase/app";
import axios from "axios";

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function createTag(title, color) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.post(
        `https://api.happybuilding.app/a/building/settings/tags`,
        {
          title,
          color,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function fetchTags() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/building/settings/tags`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const tags = [];
      data.items.forEach((tag) => {
        tags.push({
          id: tag.id,
          title: tag.title,
          color: tag.color,
        });
      });

      resolve(tags);
    } catch {
      reject();
    }
  });
}

export function removeTag(tagId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.delete(
        `https://api.happybuilding.app/a/building/settings/tags/${tagId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve();
    } catch {
      reject();
    }
  });
}

export function fetchNotifications() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      const { data } = await axios.get(
        `https://api.happybuilding.app/a/building/settings/notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const notifications = [];
      data.items.forEach((notification) => {
        notifications.push({
          id: notification.id,
          title: notification.title,
          subtitle: notification.subtitle,
          value: notification.value,
        });
      });

      resolve(notifications);
    } catch {
      reject();
    }
  });
}

export function updateNotificationSetting(id, toggled) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getFirebaseToken();
      await axios.patch(
        `https://api.happybuilding.app/a/building/settings/notifications/${id}`,
        {
          value: toggled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      resolve();
    } catch {
      reject();
    }
  });
}
