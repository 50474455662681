import React from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "./LoadingScreen";
import LoginProviders from "./LoginProviders";

const LoginScreen = () => {
  const authentication = useSelector((state) => state.authentication);

  if (!authentication.loggedIn && !authentication.initializing) {
    return (
      <div>
        <LoginProviders />
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
};

export default LoginScreen;
