import React from "react";
import ModuleHeader from "../../../components/ModuleHeader";
import { withRouter } from "react-router-dom";

const ElevatorBookingScreen = ({ history }) => {
  return (
    <>
      <ModuleHeader title="Concierge Services" subTitle="Elevator Bookings" />
    </>
  );
};

export default withRouter(ElevatorBookingScreen);
