import appConfig from "./app/config";
import conciergeConfig from "./concierge/config";
import signConfig from "./building/config";
import organizationConfig from "./organization/config";
import buildingConfig from "./building/config";

export const ModuleMap = {
  app: appConfig,
  building: buildingConfig,
  concierge: conciergeConfig,
  sign: signConfig,
  organization: organizationConfig,
};
