import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalDialog extends React.Component {
  render() {
    const { active, title, body, confirmColor, cancelColor } = this.props;
    return (
      <div>
        <Modal isOpen={active} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{body}</ModalBody>
          <ModalFooter>
            <Button color={confirmColor} onClick={this.onConfirmPressed}>
              Confirm
            </Button>{' '}
            <Button color={cancelColor} onClick={this.onCancelPressed}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  onCancelPressed = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onConfirmPressed = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };
}

export default ModalDialog;
