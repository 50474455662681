const initialState = {
  initializing: true,
  loggedIn: false,
  user: {
    email: null,
    name: null,
    access: {},
  },
  processing: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_USER_LOGIN_PROCESSING": {
      console.log("Auth: Login Processing");
      return {
        ...state,
        initializing: false,
        processing: true,
        error: false,
      };
    }
    case "AUTH_USER_LOGIN_SUCCESS": {
      console.log("Auth: Login Success");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: false,
        loggedIn: true,
        user: {
          email: action.payload?.user?.email,
          name: action.payload?.user?.displayName,
          access: processAccessAndPermissions(action.payload),
        },
      };
    }
    case "AUTH_USER_LOGIN_ERROR": {
      console.log("Auth: Login Error");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: true,
        loggedIn: false,
        user: null,
      };
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      console.log("Auth: Silent Logout");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: false,
        loggedIn: false,
        user: null,
      };
    }
    default: {
      return state;
    }
  }
};

function processAccessAndPermissions(payload) {
  // what we currently get back from the server is good for moment
  return payload.access;
}
