import store from "../store.js";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import ReactGA from "react-ga";

export function authStateMonitor() {
  firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {
      // fetch new API for test
      try {
        const token = await getFirebaseToken();
        const { data } = await axios.get(
          "https://api.happybuilding.app/auth/login",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        ReactGA.set({ userId: user.uid });

        // notify that our user is logged in
        store.dispatch({
          type: "AUTH_USER_LOGIN_SUCCESS",
          payload: data,
        });
      } catch {
        console.log("Auth Error");
        forceLogoutAndNotify();
      }
    } else {
      // notify we have silently logged out
      store.dispatch({
        type: "AUTH_USER_SILENT_LOGOUT",
        payload: {},
      });
    }
  });
}
const provider = new firebase.auth.GoogleAuthProvider();
var appleProvider = new firebase.auth.OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");

export function signInWithApple() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(appleProvider)
      .then((result) => {
        firebase
          .auth()
          .signInWithCredential(result.credential)
          .then(() => {
            console.log("Logged in with apple account");
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function signInWithGoogle() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        firebase
          .auth()
          .signInWithCredential(result.credential)
          .then(() => {
            console.log("Logged in with google account");
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

function forceLogoutAndNotify() {
  firebase
    .auth()
    .signOut()
    .then(function () {
      // dispatch error
      store.dispatch({
        type: "AUTH_USER_LOGIN_ERROR",
        payload: {},
      });
    });
}

export async function fetchInvitations(userId) {
  return new Promise(async (resolve, reject) => {
    const token = await getFirebaseToken();

    const headers = {};
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }
    axios
      .get(
        "https://us-central1-brooklyn-a5e4d.cloudfunctions.net/auth/v1/fetchInvitations",
        {
          headers: headers,
        }
      )
      .then(function (response) {
        resolve(response.data.invites);
      })
      .catch(function (error) {
        reject([]);
      });
  });
}

export async function validateInvitation(code, email, id) {
  return new Promise(async (resolve, reject) => {
    const token = await getFirebaseToken();

    const headers = {};
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }
    axios
      .get(
        "https://us-central1-brooklyn-a5e4d.cloudfunctions.net/auth/v1/validateInvitation",
        {
          headers: headers,
          params: {
            id: id,
            email: email,
            code: code,
          },
        }
      )
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        const errorCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error;
        const errorStatus = error && error.response && error.response.status;
        reject({ status: errorStatus, code: errorCode });
      });
  });
}

function getFirebaseToken() {
  return new Promise((resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => resolve(token))
        .catch(() => resolve(""));
    } else {
      resolve("");
    }
  });
}

export function userLogout() {
  firebase.auth().signOut();
}

export function userLogin(email, password) {
  store.dispatch({
    type: "AUTH_USER_LOGIN_PROCESSING",
    payload: {},
  });

  // attempt to sign in
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(function () {
      // allow authStateMonitor to log us in now.
    })
    .catch(function (error) {
      console.log("AuthAction: big error, bigly");

      // notify error
      store.dispatch({
        type: "AUTH_USER_LOGIN_ERROR",
        payload: {},
      });
    });
}
